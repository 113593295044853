var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-content item-create" }, [
    _c("div", { staticClass: "page-header" }, [
      _c("h3", { staticClass: "page-title" }, [
        _vm._v(
          _vm._s(
            _vm.isEdit ? _vm.$t("items.edit_bill") : _vm.$t("items.new_bill")
          )
        ),
      ]),
      _vm._v(" "),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              {
                attrs: { slot: "item-title", to: "/invoices" },
                slot: "item-title",
              },
              [_vm._v(_vm._s(_vm.$t("general.home")))]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              {
                attrs: { slot: "item-title", to: "/bill-ty" },
                slot: "item-title",
              },
              [_vm._v(_vm._s(_vm.$tc("items.bill_ty", 2)))]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item" }, [
          _c("a", { attrs: { href: "#" } }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.isEdit
                    ? _vm.$t("items.edit_bill")
                    : _vm.$t("items.new_bill")
                )
            ),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col col-12 col-md-12 col-lg-6" }, [
        _c("div", { staticClass: "card" }, [
          _c(
            "form",
            {
              attrs: { action: "" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submitItem.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "card-body" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _vm.dispatchList && _vm.dispatchList.length
                      ? _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { staticClass: "form-label" }, [
                              _vm._v(_vm._s(_vm.$t("items.dispatch"))),
                            ]),
                            _c("span", { staticClass: "text-danger" }, [
                              _vm._v(" *"),
                            ]),
                            _vm._v(" "),
                            _c("base-select", {
                              attrs: {
                                multiple: false,
                                options: _vm.dispatchList,
                                searchable: true,
                                "show-labels": false,
                                "allow-empty": false,
                                disabled: _vm.isEdit,
                                "custom-label": _vm.dispatchWithAmount,
                                "track-by": "id",
                              },
                              on: {
                                select: _vm.addDispatch,
                                remove: _vm.removeDispatch,
                              },
                              model: {
                                value: _vm.dispatch,
                                callback: function ($$v) {
                                  _vm.dispatch = $$v
                                },
                                expression: "dispatch",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v(_vm._s(_vm.$t("items.name"))),
                    ]),
                    _c("span", { staticClass: "text-danger" }, [_vm._v(" *")]),
                    _vm._v(" "),
                    _c("base-input", {
                      attrs: {
                        invalid: _vm.$v.formData.name.$error,
                        focus: "",
                        type: "text",
                        name: "name",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$v.formData.name.$touch()
                        },
                      },
                      model: {
                        value: _vm.formData.name,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData,
                            "name",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formData.name",
                      },
                    }),
                    _vm._v(" "),
                    _vm.$v.formData.name.$error
                      ? _c("div", [
                          !_vm.$v.formData.name.required
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("validation.required")) + " "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.$v.formData.name.minLength
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$tc(
                                        "validation.name_min_length",
                                        _vm.$v.formData.name.$params.minLength
                                          .min,
                                        {
                                          count:
                                            _vm.$v.formData.name.$params
                                              .minLength.min,
                                        }
                                      )
                                    ) +
                                    "\n                "
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", [_vm._v(_vm._s(_vm.$t("items.bill_ty")))]),
                    _c("span", { staticClass: "text-danger" }, [_vm._v(" *")]),
                    _vm._v(" "),
                    _c("base-input", {
                      attrs: {
                        invalid: _vm.$v.formData.bill_ty.$error,
                        focus: "",
                        type: "text",
                        name: "bill_ty",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$v.formData.bill_ty.$touch()
                        },
                      },
                      model: {
                        value: _vm.formData.bill_ty,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData,
                            "bill_ty",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formData.bill_ty",
                      },
                    }),
                    _vm._v(" "),
                    _vm.$v.formData.bill_ty.$error
                      ? _c("div", [
                          !_vm.$v.formData.bill_ty.required
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(_vm._s(_vm.$t("validation.required"))),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { attrs: { for: "date" } }, [
                      _vm._v(_vm._s(_vm.$t("items.date"))),
                    ]),
                    _c("span", { staticClass: "text-danger" }, [_vm._v(" *")]),
                    _vm._v(" "),
                    _c("base-date-picker", {
                      attrs: {
                        invalid: _vm.$v.formData.date.$error,
                        "calendar-button": true,
                        "calendar-button-icon": "calendar",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$v.formData.date.$touch()
                        },
                      },
                      model: {
                        value: _vm.formData.date,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "date", $$v)
                        },
                        expression: "formData.date",
                      },
                    }),
                    _vm._v(" "),
                    _vm.$v.formData.date.$error
                      ? _c("div", [
                          !_vm.$v.formData.date.required
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(_vm._s(_vm.$t("validation.required"))),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { attrs: { for: "description" } }, [
                      _vm._v(_vm._s(_vm.$t("items.description"))),
                    ]),
                    _vm._v(" "),
                    _c("base-text-area", {
                      attrs: { rows: "2", name: "description" },
                      on: {
                        input: function ($event) {
                          return _vm.$v.formData.description.$touch()
                        },
                      },
                      model: {
                        value: _vm.formData.description,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "description", $$v)
                        },
                        expression: "formData.description",
                      },
                    }),
                    _vm._v(" "),
                    _vm.$v.formData.description.$error
                      ? _c("div", [
                          !_vm.$v.formData.description.maxLength
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("validation.description_maxlength")
                                  )
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("div", { staticClass: "fileUpload btn btn-default" }, [
                    _c("label", { staticClass: "upload mb-0" }, [
                      _c("input", {
                        attrs: {
                          type: "file",
                          accept: "image/*",
                          name: "image",
                        },
                        on: { change: _vm.uploadImage },
                      }),
                      _vm._v(
                        "\n                    Upload Image\n                "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.formData.image
                    ? _c("div", [
                        _c("a", {
                          staticStyle: { "font-size": "12px" },
                          attrs: { href: _vm.formData.image, target: "_blank" },
                        }),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(
                      "base-button",
                      {
                        staticClass: "collapse-button",
                        attrs: {
                          loading: _vm.isLoading,
                          disabled: _vm.isLoading,
                          icon: "save",
                          color: "theme",
                          type: "submit",
                        },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.isEdit
                                ? _vm.$t("items.update_item")
                                : _vm.$t("items.save_item")
                            ) +
                            "\n              "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.previewImage
        ? _c("div", { staticClass: "col-sm-6" }, [
            _c("img", {
              staticClass: "uploading-image",
              staticStyle: { width: "100%", height: "90%" },
              attrs: { src: _vm.previewImage },
            }),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }