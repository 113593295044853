var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "invoice-create-page main-content" },
    [
      _c("div", { staticClass: "page-header" }, [
        _c(
          "div",
          { staticClass: "page-actions row" },
          [
            _c(
              "router-link",
              {
                staticClass: "col-xs-2",
                attrs: { slot: "item-title", to: "/invoices" },
                slot: "item-title",
              },
              [
                _c(
                  "base-button",
                  {
                    attrs: { size: "large", icon: "envelope", color: "theme" },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("invoices.title")) +
                        "\n        "
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass: "ml-2 col-xs-2",
                attrs: { slot: "item-title", to: "/invoices/bulk" },
                slot: "item-title",
              },
              [
                _c(
                  "base-button",
                  {
                    attrs: { size: "large", icon: "envelope", color: "theme" },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("invoices.bulk_title")) +
                        "\n        "
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      !_vm.initLoading
        ? _c(
            "form",
            {
              staticClass: "ipad-width",
              attrs: { action: "" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submitInvoiceData.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "page-header" }, [
                _vm.$route.name === "invoices.edit"
                  ? _c("h3", { staticClass: "page-title" }, [
                      _vm._v(_vm._s(_vm.$t("invoices.edit_invoice"))),
                    ])
                  : _c("h3", { staticClass: "page-title" }, [
                      _vm._v(_vm._s(_vm.$t("invoices.new_invoice")) + " "),
                    ]),
                _vm._v(" "),
                _c("ol", { staticClass: "breadcrumb" }, [
                  _c(
                    "li",
                    { staticClass: "breadcrumb-item" },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: { slot: "item-title", to: "/invoices" },
                          slot: "item-title",
                        },
                        [_vm._v(_vm._s(_vm.$tc("invoices.invoice", 2)))]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.$route.name === "invoices.edit"
                    ? _c("li", { staticClass: "breadcrumb-item" }, [
                        _vm._v(_vm._s(_vm.$t("invoices.edit_invoice"))),
                      ])
                    : _c("li", { staticClass: "breadcrumb-item" }, [
                        _vm._v(_vm._s(_vm.$t("invoices.new_invoice"))),
                      ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row invoice-input-group" }, [
                _c(
                  "div",
                  { staticClass: "col-md-6 invoice-customer-container mb-2" },
                  [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v(_vm._s(_vm.$t("invoices.estimate-list"))),
                    ]),
                    _vm._v(" "),
                    _c("base-select", {
                      attrs: {
                        options: _vm.estimateList,
                        required: "required",
                        searchable: true,
                        "show-labels": false,
                        "allow-empty": false,
                        disabled: _vm.isDisabled || _vm.estimateDisabled,
                        placeholder: _vm.$t("receipts.select_a_list"),
                        label: "estimate_number",
                        "track-by": "id",
                      },
                      model: {
                        value: _vm.setEstimate,
                        callback: function ($$v) {
                          _vm.setEstimate = $$v
                        },
                        expression: "setEstimate",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-6 invoice-customer-container mb-2" },
                  [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v(_vm._s(_vm.$t("receipts.list"))),
                    ]),
                    _c("span", { staticClass: "text-danger" }, [_vm._v(" *")]),
                    _vm._v(" "),
                    _c("base-select", {
                      attrs: {
                        invalid: _vm.$v.newInvoice.debtors.$error,
                        options: _vm.sundryDebtorsList,
                        required: "required",
                        searchable: true,
                        "show-labels": false,
                        "allow-empty": false,
                        name: "edit" === _vm.urlMode ? "party" : "party_name",
                        disabled: _vm.isDisabled || _vm.estimateSelected,
                        placeholder: _vm.$t("receipts.select_a_list"),
                        label: "name",
                        "track-by": "id",
                      },
                      model: {
                        value: _vm.setInvoiceDebtor,
                        callback: function ($$v) {
                          _vm.setInvoiceDebtor = $$v
                        },
                        expression: "setInvoiceDebtor",
                      },
                    }),
                    _vm._v(" "),
                    _vm.$v.newInvoice.debtors.$error
                      ? _c("div", [
                          !_vm.$v.newInvoice.debtors.required
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(_vm._s(_vm.$tc("validation.required"))),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-4 col-sm-6 collapse-input" }, [
                  _c("label", [
                    _vm._v(
                      _vm._s(_vm.$tc("invoices.invoice", 1)) +
                        " " +
                        _vm._s(_vm.$t("invoices.date"))
                    ),
                    _c("span", { staticClass: "text-danger" }, [_vm._v(" * ")]),
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.newInvoice.invoice_date,
                        expression: "newInvoice.invoice_date",
                      },
                    ],
                    staticClass: "base-prefix-input",
                    attrs: {
                      type: "date",
                      "data-date": "",
                      "data-date-format": "DD/MM/YYYY",
                      disabled: _vm.isDisabled,
                    },
                    domProps: { value: _vm.newInvoice.invoice_date },
                    on: {
                      change: function ($event) {
                        return _vm.$v.newInvoice.invoice_date.$touch()
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.newInvoice,
                          "invoice_date",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.$v.newInvoice.invoice_date.$error &&
                  !_vm.$v.newInvoice.invoice_date.required
                    ? _c("span", { staticClass: "text-danger" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("validation.required")) + " "
                        ),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-4 col-sm-6 collapse-input" },
                  [
                    _c("label", [
                      _vm._v(_vm._s(_vm.$t("invoices.invoice_number"))),
                      _c("span", { staticClass: "text-danger" }, [
                        _vm._v(" * "),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("base-prefix-input", {
                      attrs: {
                        icon: "hashtag",
                        invalid: _vm.$v.invoiceNumAttribute.$error,
                        prefix: _vm.invoicePrefix,
                        "prefix-width": 55,
                        disabled: true,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$v.invoiceNumAttribute.$touch()
                        },
                      },
                      model: {
                        value: _vm.invoiceNumAttribute,
                        callback: function ($$v) {
                          _vm.invoiceNumAttribute = $$v
                        },
                        expression: "invoiceNumAttribute",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.$v.invoiceNumAttribute.$error &&
                              !_vm.$v.invoiceNumAttribute.required,
                            expression:
                              "$v.invoiceNumAttribute.$error && !$v.invoiceNumAttribute.required",
                          },
                        ],
                        staticClass: "text-danger mt-1",
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$tc("validation.required")) + "  "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-4 col-sm-6 collapse-input" },
                  [
                    _c("label", [
                      _vm._v(_vm._s(_vm.$t("invoices.ref_number"))),
                    ]),
                    _vm._v(" "),
                    _c("base-prefix-input", {
                      attrs: {
                        icon: "hashtag",
                        invalid: _vm.$v.referenceNumAttribute.$error,
                        prefix: _vm.referencePrefix,
                        "prefix-width": 55,
                        disabled: true,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$v.referenceNumAttribute.$touch()
                        },
                      },
                      model: {
                        value: _vm.referenceNumAttribute,
                        callback: function ($$v) {
                          _vm.referenceNumAttribute = $$v
                        },
                        expression: "referenceNumAttribute",
                      },
                    }),
                    _vm._v(" "),
                    _vm.$v.referenceNumAttribute.$error
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            _vm._s(_vm.$tc("validation.ref_number_required"))
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "table-responsive" }, [
                _c(
                  "table",
                  { staticClass: "table item-table" },
                  [
                    _c("colgroup", [
                      _c("col", { staticStyle: { width: "40%" } }),
                      _vm._v(" "),
                      _c("col", { staticStyle: { width: "10%" } }),
                      _vm._v(" "),
                      _c("col", { staticStyle: { width: "15%" } }),
                      _vm._v(" "),
                      _c("col", { staticStyle: { width: "15%" } }),
                      _vm._v(" "),
                      _vm.discountPerInventory === "YES"
                        ? _c("col", { staticStyle: { width: "15%" } })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("col", { staticStyle: { width: "15%" } }),
                    ]),
                    _vm._v(" "),
                    _c("thead", { staticClass: "item-table-header" }, [
                      _c("tr", [
                        _c("th", { staticClass: "text-left" }, [
                          _c(
                            "span",
                            {
                              staticClass:
                                "column-heading heading-1 item-heading",
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.$tc("invoices.inventory.title", 2)
                                  ) +
                                  "\n              "
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("th", { staticClass: "text-right" }, [
                          _c("span", { staticClass: "column-heading" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$t("invoices.inventory.quantity")) +
                                "\n              "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("th", { staticClass: "text-left" }, [
                          _c("span", { staticClass: "column-heading" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$t("invoices.inventory.price")) +
                                "\n              "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("th", { staticClass: "text-left" }, [
                          _c("span", { staticClass: "column-heading" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.$t("invoices.inventory.sale_price")
                                ) +
                                "\n              "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _vm.discountPerInventory === "YES"
                          ? _c("th", { staticClass: "text-right" }, [
                              _c("span", { staticClass: "column-heading" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$t("invoices.inventory.discount")
                                    ) +
                                    "\n              "
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("th", { staticClass: "text-right" }, [
                          _c(
                            "span",
                            { staticClass: "column-heading amount-heading" },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("invoices.inventory.amount")) +
                                  "\n              "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "draggable",
                      {
                        staticClass: "item-body",
                        attrs: { tag: "tbody", handle: ".handle" },
                        model: {
                          value: _vm.inventoryBind,
                          callback: function ($$v) {
                            _vm.inventoryBind = $$v
                          },
                          expression: "inventoryBind",
                        },
                      },
                      _vm._l(_vm.inventoryBind, function (each, index) {
                        return _c("invoice-inventory", {
                          key: each.name + index + each.quantity,
                          ref: "invoiceInventory",
                          refInFor: true,
                          attrs: {
                            index: index,
                            "inventory-data": each,
                            currency: _vm.currency,
                            "discount-per-inventory": _vm.discountPerInventory,
                            "is-disable": _vm.$route.query.d === "true",
                            "inventory-type": "invoice",
                            "inventory-list": _vm.inventoryListBind,
                            "inventory-negative": _vm.inventoryNegative,
                            "is-edit": _vm.$route.name === "invoices.edit",
                          },
                          on: {
                            remove: _vm.removeInventory,
                            update: _vm.updateInventoryBounce,
                            inventoryValidate: _vm.checkInventoryData,
                            endlist: _vm.showEndList,
                          },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _vm.showAddNewInventory
                ? _c(
                    "button",
                    {
                      staticClass: "add-item-action add-invoice-item",
                      attrs: { disabled: _vm.isDisabled },
                      on: { click: _vm.addInventory },
                    },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "mr-2",
                        attrs: { icon: "shopping-basket" },
                      }),
                      _vm._v(
                        "\n      " +
                          _vm._s(_vm.$t("invoices.add_item")) +
                          "\n    "
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.showEndOfList
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      staticStyle: { margin: "10px" },
                      on: { click: _vm.removeEndOfList },
                    },
                    [_vm._v("\n      End Of List\n    ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "invoice-foot" }, [
                _c(
                  "div",
                  [
                    _c("label", [_vm._v(_vm._s(_vm.$t("invoices.notes")))]),
                    _vm._v(" "),
                    _c("base-text-area", {
                      attrs: { rows: "3", cols: "50" },
                      on: {
                        input: function ($event) {
                          return _vm.$v.newInvoice.notes.$touch()
                        },
                      },
                      model: {
                        value: _vm.newInvoice.notes,
                        callback: function ($$v) {
                          _vm.$set(_vm.newInvoice, "notes", $$v)
                        },
                        expression: "newInvoice.notes",
                      },
                    }),
                    _vm._v(" "),
                    _vm.$v.newInvoice.notes.$error
                      ? _c("div", [
                          !_vm.$v.newInvoice.notes.maxLength
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("validation.notes_maxlength"))
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "invoice-total" }, [
                  _c("div", { staticClass: "section" }, [
                    _c("label", { staticClass: "invoice-label" }, [
                      _vm._v(_vm._s(_vm.$t("invoices.quantity"))),
                    ]),
                    _vm._v(" "),
                    _c("label", {}, [
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.totalQuantity(_vm.inventoryBind)
                          ),
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "section" }, [
                    _c("label", { staticClass: "invoice-label" }, [
                      _vm._v(_vm._s(_vm.$t("invoices.sub_total"))),
                    ]),
                    _vm._v(" "),
                    _c("label", { staticClass: "invoice-amount" }, [
                      _vm._v(
                        "\n            ₹ " +
                          _vm._s(_vm.subtotal) +
                          "\n          "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.incomeLedgerList.length
                    ? _c("div", { staticClass: "section" }, [
                        _c("div", { staticClass: "row align-items-center" }, [
                          _c("div", { staticClass: "pl-3" }, [
                            _c("label", { staticClass: "form-label" }, [
                              _c("strong", [
                                _vm._v(_vm._s(_vm.$t("invoices.add"))),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "pl-3 mr-5" },
                            [
                              _c("base-select", {
                                attrs: {
                                  options: _vm.incomeLedgerList,
                                  required: "required",
                                  searchable: true,
                                  "show-labels": false,
                                  "allow-empty": false,
                                  disabled: _vm.isDisabled,
                                  placeholder: _vm.$t("receipts.select_a_list"),
                                  label: "name",
                                  "track-by": "id",
                                },
                                model: {
                                  value: _vm.income_ledger,
                                  callback: function ($$v) {
                                    _vm.income_ledger = $$v
                                  },
                                  expression: "income_ledger",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c("base-input", {
                              staticStyle: { width: "100px" },
                              attrs: {
                                disabled: this.income_ledger === null,
                                type: "number",
                                min: "0",
                                "input-class": "item-discount",
                              },
                              model: {
                                value: _vm.income_ledger_value,
                                callback: function ($$v) {
                                  _vm.income_ledger_value = $$v
                                },
                                expression: "income_ledger_value",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.expenseLedgerList.length
                    ? _c(
                        "div",
                        { staticClass: "section" },
                        [
                          _c("div", { staticClass: "row align-items-center" }, [
                            _c("div", { staticClass: "pl-3 mb-2" }, [
                              _c("label", { staticClass: "form-label" }, [
                                _c("strong", [
                                  _vm._v(_vm._s(_vm.$t("invoices.less"))),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "pl-3 mb-2 mr-5" },
                              [
                                _c("base-select", {
                                  attrs: {
                                    options: _vm.expenseLedgerList,
                                    required: "required",
                                    searchable: true,
                                    "show-labels": false,
                                    "allow-empty": false,
                                    disabled: _vm.isDisabled,
                                    placeholder: _vm.$t(
                                      "receipts.select_a_list"
                                    ),
                                    label: "name",
                                    "track-by": "id",
                                  },
                                  model: {
                                    value: _vm.expense_ledger,
                                    callback: function ($$v) {
                                      _vm.expense_ledger = $$v
                                    },
                                    expression: "expense_ledger",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("base-input", {
                            staticStyle: { width: "100px" },
                            attrs: {
                              disabled: this.expense_ledger === null,
                              type: "number",
                              min: "0",
                              "input-class": "item-discount",
                            },
                            model: {
                              value: _vm.expense_ledger_value,
                              callback: function ($$v) {
                                _vm.expense_ledger_value = $$v
                              },
                              expression: "expense_ledger_value",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.discountPerInventory === "NO" ||
                  _vm.discountPerInventory === null
                    ? _c("div", { staticClass: "section mt-2" }, [
                        _c("label", { staticClass: "invoice-label" }, [
                          _vm._v(_vm._s(_vm.$t("invoices.discount"))),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "btn-group discount-drop-down",
                            attrs: { role: "group" },
                          },
                          [
                            _c("base-input", {
                              attrs: {
                                invalid: _vm.$v.newInvoice.discount_val.$error,
                                "input-class": "item-discount",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.$v.newInvoice.discount_val.$touch()
                                },
                              },
                              model: {
                                value: _vm.discount,
                                callback: function ($$v) {
                                  _vm.discount = $$v
                                },
                                expression: "discount",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "v-dropdown",
                              { attrs: { "show-arrow": false } },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn item-dropdown dropdown-toggle",
                                    attrs: {
                                      slot: "activator",
                                      type: "button",
                                      "data-toggle": "dropdown",
                                      "aria-haspopup": "true",
                                      "aria-expanded": "false",
                                    },
                                    slot: "activator",
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.newInvoice.discount_type ==
                                            "fixed"
                                            ? _vm.currency.symbol
                                            : "%"
                                        ) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("v-dropdown-item", [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "dropdown-item",
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.selectFixed.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(_vm.$t("general.fixed")) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("v-dropdown-item", [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "dropdown-item",
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.selectPercentage.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(_vm.$t("general.percentage")) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "section border-top mt-3" }, [
                    _c("label", { staticClass: "invoice-label" }, [
                      _vm._v(
                        _vm._s(_vm.$t("invoices.total")) +
                          " " +
                          _vm._s(_vm.$t("invoices.amount")) +
                          ":"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("label", { staticClass: "invoice-amount total" }, [
                      _vm._v(
                        "\n            ₹ " + _vm._s(_vm.total) + "\n          "
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "page-actions row",
                  staticStyle: { "margin-left": "3px" },
                },
                [
                  _c(
                    "base-button",
                    {
                      staticClass: "invoice-action-btn",
                      attrs: {
                        loading: _vm.isLoading,
                        disabled: _vm.isLoading,
                        icon: "save",
                        color: "theme",
                        type: "submit",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("invoices.save_invoice")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  this.$route.name === "invoices.edit"
                    ? _c(
                        "base-button",
                        {
                          staticClass: "report-button ml-2",
                          attrs: { outline: "", color: "theme" },
                          on: {
                            click: function ($event) {
                              return _vm.sendReports()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("reports.send_report")) +
                              "\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          )
        : _c("base-loader"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }