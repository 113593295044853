var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "invoice-create-page main-content" }, [
    _c("div", { staticClass: "page-header" }, [
      _c("h3", { staticClass: "page-title" }, [
        _vm._v(_vm._s(_vm.$tc("settings.setting", 1))),
      ]),
      _vm._v(" "),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              {
                attrs: { slot: "item-title", to: "/invoices" },
                slot: "item-title",
              },
              [_vm._v(_vm._s(_vm.$t("general.home")))]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              {
                attrs: { slot: "item-title", to: "/settings/user-profile" },
                slot: "item-title",
              },
              [_vm._v(_vm._s(_vm.$tc("settings.setting", 2)))]
            ),
          ],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row settings-container" }, [
      _c("div", { staticClass: "col-lg-3 settings-sidebar-container" }, [
        _c(
          "ol",
          { staticClass: "settings-sidebar" },
          [
            _vm._l(
              _vm.menuItems.filter((each) => each.meta.includes(_vm.role)),
              function (menuItem, index) {
                return _c(
                  "li",
                  { key: index, staticClass: "settings-menu-item" },
                  [
                    _c(
                      "router-link",
                      {
                        class: [
                          "link-color",
                          { "active-setting": _vm.hasActiveUrl(menuItem.link) },
                        ],
                        attrs: { to: menuItem.link },
                      },
                      [
                        _c("font-awesome-icon", {
                          staticClass: "setting-icon",
                          attrs: { icon: [menuItem.iconType, menuItem.icon] },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "menu-title ml-3" }, [
                          _vm._v(_vm._s(_vm.$t(menuItem.title))),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }
            ),
            _vm._v(" "),
            "admin" === _vm.role
              ? _c("li", [
                  _c(
                    "a",
                    {
                      staticClass: "link-color ml-2",
                      attrs: { href: "#" },
                      on: { click: _vm.showModal },
                    },
                    [
                      _c(
                        "svg",
                        {
                          staticClass:
                            "setting-icon svg-inline--fa fa-trash fa-w-14",
                          attrs: {
                            "aria-hidden": "true",
                            focusable: "false",
                            "data-prefix": "fa",
                            "data-icon": "trash",
                            role: "img",
                            xmlns: "http://www.w3.org/2000/svg",
                            viewBox: "0 0 448 512",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              fill: "currentColor",
                              d: "M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z",
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "menu-title ml-3 pl-1" }, [
                        _vm._v("All Data"),
                      ]),
                    ]
                  ),
                ])
              : _vm._e(),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-lg-9" },
        [
          _c(
            "transition",
            { attrs: { name: "fade", mode: "out-in" } },
            [_c("router-view")],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }