var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-content expenses" }, [
    _c(
      "form",
      {
        attrs: { action: "" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.sendData.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "page-header" }, [
          _c("h3", { staticClass: "page-title" }, [
            _vm._v(
              _vm._s(
                _vm.isEdit
                  ? _vm.$t("expenses.edit_expense")
                  : _vm.$t("expenses.new_expense")
              )
            ),
          ]),
          _vm._v(" "),
          _c("ol", { staticClass: "breadcrumb" }, [
            _c(
              "li",
              { staticClass: "breadcrumb-item" },
              [
                _c(
                  "router-link",
                  {
                    attrs: { slot: "item-title", to: "/invoices" },
                    slot: "item-title",
                  },
                  [_vm._v(_vm._s(_vm.$t("general.home")))]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "breadcrumb-item" },
              [
                _c(
                  "router-link",
                  {
                    attrs: { slot: "item-title", to: "/expenses" },
                    slot: "item-title",
                  },
                  [_vm._v(_vm._s(_vm.$tc("expenses.expense", 2)))]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("li", { staticClass: "breadcrumb-item" }, [
              _c("a", { attrs: { href: "#" } }, [
                _vm._v(
                  _vm._s(
                    _vm.isEdit
                      ? _vm.$t("expenses.edit_expense")
                      : _vm.$t("expenses.new_expense")
                  )
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "page-actions row header-button-container" },
            [
              _vm.isReceiptAvailable
                ? _c("div", { staticClass: "col-xs-2 mr-4" }, [
                    _c(
                      "a",
                      { attrs: { href: _vm.getReceiptUrl } },
                      [
                        _c(
                          "base-button",
                          {
                            attrs: {
                              loading: _vm.isLoading,
                              icon: "download",
                              color: "theme",
                              outline: "",
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("expenses.download_receipt")) +
                                "\n            "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-12" }, [
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-body" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "form-group col-sm-6" },
                    [
                      _c("label", { staticClass: "control-label" }, [
                        _vm._v(_vm._s(_vm.$t("expenses.category"))),
                      ]),
                      _c("span", { staticClass: "text-danger" }, [
                        _vm._v(" * "),
                      ]),
                      _vm._v(" "),
                      _c(
                        "base-select",
                        {
                          ref: "baseSelect",
                          attrs: {
                            options: _vm.categories,
                            invalid: _vm.$v.category.$error,
                            searchable: true,
                            "show-labels": false,
                            placeholder: _vm.$t(
                              "expenses.categories.select_a_category"
                            ),
                            label: "name",
                            "track-by": "id",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.$v.category.$touch()
                            },
                          },
                          model: {
                            value: _vm.category,
                            callback: function ($$v) {
                              _vm.category = $$v
                            },
                            expression: "category",
                          },
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "afterList" }, slot: "afterList" },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "list-add-button",
                                  attrs: { type: "button" },
                                  on: { click: _vm.openCategoryModal },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    staticClass: "icon",
                                    attrs: { icon: "cart-plus" },
                                  }),
                                  _vm._v(" "),
                                  _c("label", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "settings.expense_category.add_new_category"
                                        )
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.$v.category.$error
                        ? _c("div", [
                            !_vm.$v.category.required
                              ? _c("span", { staticClass: "text-danger" }, [
                                  _vm._v(_vm._s(_vm.$t("validation.required"))),
                                ])
                              : _vm._e(),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group col-sm-6" },
                    [
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("expenses.expense_date"))),
                      ]),
                      _c("span", { staticClass: "text-danger" }, [
                        _vm._v(" * "),
                      ]),
                      _vm._v(" "),
                      _c("base-date-picker", {
                        attrs: {
                          invalid: _vm.$v.formData.expense_date.$error,
                          "calendar-button": true,
                          "calendar-button-icon": "calendar",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$v.formData.expense_date.$touch()
                          },
                        },
                        model: {
                          value: _vm.formData.expense_date,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "expense_date", $$v)
                          },
                          expression: "formData.expense_date",
                        },
                      }),
                      _vm._v(" "),
                      _vm.$v.formData.expense_date.$error
                        ? _c("div", [
                            !_vm.$v.formData.expense_date.required
                              ? _c("span", { staticClass: "text-danger" }, [
                                  _vm._v(_vm._s(_vm.$t("validation.required"))),
                                ])
                              : _vm._e(),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group col-sm-6" },
                    [
                      _c("label", [_vm._v(_vm._s(_vm.$t("expenses.amount")))]),
                      _vm._v(" "),
                      _c("span", { staticClass: "text-danger" }, [
                        _vm._v(" * "),
                      ]),
                      _vm._v(" "),
                      _c("base-input", {
                        class: {
                          invalid: _vm.$v.formData.amount.$error,
                          "input-field": true,
                        },
                        attrs: { type: "text", name: "amount" },
                        model: {
                          value: _vm.amount,
                          callback: function ($$v) {
                            _vm.amount =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "amount",
                        },
                      }),
                      _vm._v(" "),
                      _vm.$v.formData.amount.$error
                        ? _c("div", [
                            !_vm.$v.formData.amount.required
                              ? _c("span", { staticClass: "text-danger" }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("validation.required")) + " "
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.$v.formData.amount.maxLength
                              ? _c("span", { staticClass: "text-danger" }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("validation.price_maxlength"))
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.$v.formData.amount.minValue
                              ? _c("span", { staticClass: "text-danger" }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("validation.price_minvalue"))
                                  ),
                                ])
                              : _vm._e(),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group col-sm-6" },
                    [
                      _c("label", { attrs: { for: "description" } }, [
                        _vm._v(_vm._s(_vm.$t("expenses.note"))),
                      ]),
                      _vm._v(" "),
                      _c("base-text-area", {
                        on: {
                          input: function ($event) {
                            return _vm.$v.formData.notes.$touch()
                          },
                        },
                        model: {
                          value: _vm.formData.notes,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "notes", $$v)
                          },
                          expression: "formData.notes",
                        },
                      }),
                      _vm._v(" "),
                      _vm.$v.formData.notes.$error
                        ? _c("div", [
                            !_vm.$v.formData.notes.maxLength
                              ? _c("span", { staticClass: "text-danger" }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("validation.notes_maxlength"))
                                  ),
                                ])
                              : _vm._e(),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group col-md-6" }, [
                    _c("label", { attrs: { for: "description" } }, [
                      _vm._v(_vm._s(_vm.$t("expenses.receipt")) + " : "),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "image-upload-box",
                        on: {
                          click: function ($event) {
                            return _vm.$refs.file.click()
                          },
                        },
                      },
                      [
                        _c("input", {
                          ref: "file",
                          staticClass: "d-none",
                          attrs: { type: "file" },
                          on: { change: _vm.onFileChange },
                        }),
                        _vm._v(" "),
                        _vm.previewReceipt
                          ? _c("img", {
                              staticClass: "preview-logo",
                              attrs: { src: _vm.previewReceipt },
                            })
                          : _c(
                              "div",
                              { staticClass: "upload-content" },
                              [
                                _c("font-awesome-icon", {
                                  staticClass: "upload-icon",
                                  attrs: { icon: "cloud-upload-alt" },
                                }),
                                _vm._v(" "),
                                _c("p", { staticClass: "upload-text" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("general.choose_file")) +
                                      " "
                                  ),
                                ]),
                              ],
                              1
                            ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-12" }, [
                    _c(
                      "div",
                      { staticClass: "form-group collapse-button-container" },
                      [
                        _c(
                          "base-button",
                          {
                            staticClass: "collapse-button",
                            attrs: {
                              loading: _vm.isLoading,
                              icon: "save",
                              color: "theme",
                              type: "submit",
                            },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm.isEdit
                                    ? _vm.$t("expenses.update_expense")
                                    : _vm.$t("expenses.save_expense")
                                ) +
                                "\n                  "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-xs-2" },
                  [
                    _c(
                      "base-button",
                      {
                        attrs: {
                          loading: _vm.isLoading,
                          icon: "save",
                          color: "theme",
                          type: "submit",
                        },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.isEdit
                                ? _vm.$t("expenses.update_expense")
                                : _vm.$t("expenses.save_expense")
                            ) +
                            "\n              "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }