var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "group-modal" }, [
    _c(
      "form",
      {
        attrs: { action: "" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submitGroupData.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "form-group row" }, [
            _c(
              "label",
              { staticClass: "col-sm-4 col-form-label input-label" },
              [
                _vm._v("\n          " + _vm._s(_vm.$t("groups.name"))),
                _c("span", { staticClass: "text-danger required" }, [
                  _vm._v("*"),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-sm-7" },
              [
                _c("base-input", {
                  ref: "name",
                  attrs: { invalid: _vm.$v.formData.name.$error, type: "text" },
                  on: {
                    input: function ($event) {
                      return _vm.$v.formData.name.$touch()
                    },
                  },
                  model: {
                    value: _vm.formData.name,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.formData,
                        "name",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "formData.name",
                  },
                }),
                _vm._v(" "),
                _vm.$v.formData.name.$error
                  ? _c("div", [
                      !_vm.$v.formData.name.required
                        ? _c("span", { staticClass: "text-danger" }, [
                            _vm._v(_vm._s(_vm.$tc("validation.required"))),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.$v.formData.name.minLength
                        ? _c("span", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$tc(
                                    "validation.name_min_length",
                                    _vm.$v.formData.name.$params.minLength.min,
                                    {
                                      count:
                                        _vm.$v.formData.name.$params.minLength
                                          .min,
                                    }
                                  )
                                ) +
                                "\n            "
                            ),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card-footer" },
          [
            _c(
              "base-button",
              {
                staticClass: "mr-3 display-inline",
                attrs: { outline: true, color: "theme", type: "button" },
                on: { click: _vm.closeGroupModal },
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("general.cancel")) + "\n      "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "base-button",
              {
                staticClass: "display-initial",
                attrs: {
                  loading: _vm.isLoading,
                  icon: "save",
                  color: "theme",
                  type: "submit",
                },
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("general.save")) + "\n      "
                ),
              ]
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }