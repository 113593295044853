var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-content item-create" }, [
    _c("div", { staticClass: "page-header" }, [
      _c("h3", { staticClass: "page-title" }, [
        _vm._v("\n      " + _vm._s(_vm.$t("daybook.title-2")) + "\n    "),
      ]),
      _vm._v(" "),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              {
                attrs: { slot: "item-title", to: "/ledgers" },
                slot: "item-title",
              },
              [_vm._v(_vm._s(_vm.$t("general.home")))]
            ),
          ],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-sm-12 mb-5" },
        [
          _c(
            "base-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.ledgerData,
                  expression: "ledgerData",
                },
              ],
              attrs: {
                outline: true,
                icon: ["fas", "print"],
                color: "theme",
                size: "large",
                "right-icon": "",
              },
              on: { click: _vm.printData },
            },
            [_vm._v("\n          Print\n        ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-sm-12 daysheet" },
        [
          _c(
            "table-component",
            {
              ref: "table",
              attrs: {
                data: _vm.ledgerData,
                "show-filter": false,
                "table-class": "table",
              },
            },
            [
              _c("table-column", {
                attrs: { label: _vm.$tc("daysheet.lot"), show: "lot" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _vm._v(
                          "\n                " +
                            _vm._s(row.lot) +
                            "\n              "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("table-column", {
                attrs: {
                  label: _vm.$tc("daysheet.reference-number"),
                  show: "reference_number",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _vm._v(
                          "\n                " +
                            _vm._s(row.reference_number.split("-")[2]) +
                            "\n              "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("table-column", {
                attrs: { label: _vm.$tc("daysheet.party-name"), show: "party" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _vm._v(
                          "\n                 " +
                            _vm._s(row.party) +
                            "\n              "
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "row", attrs: { id: "to_print_table" } }, [
            _c("div", { staticClass: "col-md-12 mb-2" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$tc("daysheet.date")) +
                  " " +
                  _vm._s(new Date().toJSON().slice(0, 10)) +
                  "\n            "
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-sm-12" },
              [
                _c(
                  "table-component",
                  {
                    ref: "table",
                    attrs: {
                      id: "to_print_table",
                      data: _vm.ledgerData,
                      "show-filter": false,
                      "table-class": "table",
                    },
                  },
                  [
                    _c("table-column", {
                      attrs: { label: _vm.$tc("daysheet.lot"), show: "lot" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _vm._v(
                                "\n                " +
                                  _vm._s(row.lot) +
                                  "\n              "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("table-column", {
                      attrs: { label: _vm.$tc("daysheet.sign"), show: "lot" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (row) {
                            return undefined
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("table-column", {
                      attrs: {
                        label: _vm.$tc("daysheet.pm"),
                        show: "reference_number",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _vm._v(
                                "\n                " +
                                  _vm._s(row.reference_number.split("-")[2]) +
                                  "\n              "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("table-column", {
                      attrs: { label: _vm.$tc("daysheet.name"), show: "party" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _vm._v(
                                "\n                 " +
                                  _vm._s(row.party) +
                                  "\n              "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("table-column", {
                      attrs: { label: _vm.$tc("daysheet.city"), show: "party" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (row) {
                            return undefined
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("table-column", {
                      attrs: {
                        label: _vm.$tc("daysheet.transport"),
                        show: "party",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (row) {
                            return undefined
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }