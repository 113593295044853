var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-content item-create" }, [
    _c("div", { staticClass: "page-header" }, [
      _c("h3", { staticClass: "page-title" }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm.ledgerData ? _vm.ledgerData.account : "") +
            "\n    "
        ),
      ]),
      _vm._v(" "),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              {
                attrs: { slot: "item-title", to: "/invoices" },
                slot: "item-title",
              },
              [_vm._v(_vm._s(_vm.$t("general.home")))]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              {
                attrs: { slot: "item-title", to: "/ledgers" },
                slot: "item-title",
              },
              [_vm._v(_vm._s(_vm.$tc("ledgers.ledgers_list", 2)))]
            ),
          ],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row", staticStyle: { "margin-bottom": "80px" } },
      [
        _c("div", { staticClass: "col-sm-12" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "reports-tab-container" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-4 report-field-container" },
                    [
                      _c("label", { staticClass: "report-label" }, [
                        _vm._v(_vm._s(_vm.$t("reports.customers.date_range"))),
                      ]),
                      _vm._v(" "),
                      _c("base-select", {
                        attrs: {
                          options: _vm.dateRange,
                          "allow-empty": false,
                          "show-labels": false,
                        },
                        on: { input: _vm.onChangeDateRange },
                        model: {
                          value: _vm.selectedRange,
                          callback: function ($$v) {
                            _vm.selectedRange = $$v
                          },
                          expression: "selectedRange",
                        },
                      }),
                      _vm._v(" "),
                      _vm.$v.range.$error && !_vm.$v.range.required
                        ? _c("span", { staticClass: "text-danger" }, [
                            _vm._v(
                              " " + _vm._s(_vm.$t("validation.required")) + " "
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-md-4 report-field-container" },
                    [
                      _c("label", { staticClass: "report-label" }, [
                        _vm._v(_vm._s(_vm.$t("reports.customers.from_date"))),
                      ]),
                      _vm._v(" "),
                      _c("base-date-picker", {
                        attrs: {
                          invalid: _vm.$v.formData.from_date.$error,
                          "calendar-button": true,
                          "calendar-button-icon": "calendar",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$v.formData.from_date.$touch()
                          },
                          input: function ($event) {
                            return _vm.loadEditData()
                          },
                        },
                        model: {
                          value: _vm.formData.from_date,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "from_date", $$v)
                          },
                          expression: "formData.from_date",
                        },
                      }),
                      _vm._v(" "),
                      _vm.$v.formData.from_date.$error &&
                      !_vm.$v.formData.from_date.required
                        ? _c("span", { staticClass: "text-danger" }, [
                            _vm._v(
                              " " + _vm._s(_vm.$t("validation.required")) + " "
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-md-4 report-field-container" },
                    [
                      _c("label", { staticClass: "report-label" }, [
                        _vm._v(_vm._s(_vm.$t("reports.customers.to_date"))),
                      ]),
                      _vm._v(" "),
                      _c("base-date-picker", {
                        attrs: {
                          invalid: _vm.$v.formData.to_date.$error,
                          "calendar-button": true,
                          "calendar-button-icon": "calendar",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$v.formData.to_date.$touch()
                          },
                          input: function ($event) {
                            return _vm.loadEditData()
                          },
                        },
                        model: {
                          value: _vm.formData.to_date,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "to_date", $$v)
                          },
                          expression: "formData.to_date",
                        },
                      }),
                      _vm._v(" "),
                      _vm.$v.formData.to_date.$error &&
                      !_vm.$v.formData.to_date.required
                        ? _c("span", { staticClass: "text-danger" }, [
                            _vm._v(
                              " " + _vm._s(_vm.$t("validation.required")) + " "
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card" }, [
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _vm.isLoading
                  ? _c("base-loader", { staticClass: "table-loader" })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "table-component",
                  {
                    ref: "tableDisplay",
                    attrs: {
                      data: _vm.displayArray,
                      "show-filter": false,
                      "table-class": "table display-ledger",
                    },
                  },
                  [
                    _c("table-column", {
                      attrs: { label: _vm.$t("ledgers.date"), show: "date" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.getFormattedDate(row.date)) +
                                  "\n              "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("table-column", {
                      attrs: {
                        label: _vm.$t("ledgers.particulars"),
                        show: "particulars",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c(
                                "router-link",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: {
                                    to: {
                                      path: row.invoice_id
                                        ? `/invoices/${row.invoice_id}/edit`
                                        : row.receipt_id
                                        ? `/receipts/${row.receipt_id}/edit`
                                        : `/vouchers/${row.id}/edit`,
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(row.account) +
                                      "\n                  "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("table-column", {
                      attrs: {
                        label: _vm.$t("ledgers.voucher_type"),
                        show: "voucher_type",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _vm._v(
                                "\n                " +
                                  _vm._s(row.voucher_type) +
                                  "\n              "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("table-column", {
                      attrs: {
                        label: _vm.$t("ledgers.voucher_id"),
                        show: "id",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _vm._v(
                                "\n                " +
                                  _vm._s(row.id) +
                                  "\n              "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("table-column", {
                      attrs: {
                        label: _vm.$t("ledgers.inventory_item_quantity"),
                        show: "quantity",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    row.invoice
                                      ? row.invoice.inventories
                                          .map((k) => parseInt(k.quantity))
                                          .reduce((a, b) => a + b)
                                      : 0
                                  ) +
                                  "\n              "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("table-column", {
                      attrs: { label: _vm.$t("ledgers.debit"), show: "debit" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _vm._v(
                                "\n                ₹ " +
                                  _vm._s(
                                    row.credit
                                      ? _vm.numberWithCommas(row.credit)
                                      : "0.00"
                                  ) +
                                  "\n              "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("table-column", {
                      attrs: {
                        label: _vm.$t("ledgers.credit"),
                        show: "credit",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _vm._v(
                                "\n                ₹ " +
                                  _vm._s(
                                    row.debit
                                      ? _vm.numberWithCommas(row.debit)
                                      : "0.00"
                                  ) +
                                  "\n              "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("table-column", {
                      attrs: { label: _vm.$t("general.delete") },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              "Voucher" === row.voucher_type
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "d-block text-center",
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeVoucher(row.id)
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["fas", "trash"] },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("p", { staticClass: "row footer-total" }, [
                  _c("span", { staticClass: "mr-30" }, [
                    _vm._v("Total Quantity:"),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "ml-60" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.inventorySum) +
                        "\n            "
                    ),
                  ]),
                ]),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass: "col-sm-12",
                staticStyle: {
                  background: "#fff",
                  position: "fixed",
                  bottom: "0",
                  padding: "0",
                  right: "0",
                  height: "100px",
                },
              },
              [
                _c("p", { staticClass: "row p-footer" }, [
                  _c("span", { staticClass: "col-sm-4" }, [
                    _vm._v("Opening Balance:"),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "col-sm-4" }, [
                    _vm._v(
                      "\n              ₹ " +
                        _vm._s(
                          _vm.totalOpeningBalanceCr
                            ? _vm.numberWithCommas(_vm.totalOpeningBalanceCr)
                            : 0.0
                        ) +
                        " Dr\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "col-sm-4" }, [
                    _vm._v(
                      "\n              ₹ " +
                        _vm._s(
                          _vm.totalOpeningBalanceDr
                            ? _vm.numberWithCommas(_vm.totalOpeningBalanceDr)
                            : 0.0
                        ) +
                        " Cr\n            "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c("p", { staticClass: "row p-footer" }, [
                  _c("span", { staticClass: "col-sm-4" }, [
                    _vm._v("Current Total:"),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "col-sm-4" }, [
                    _vm._v(
                      "\n              ₹ " +
                        _vm._s(
                          _vm.currentBalanceCr
                            ? _vm.numberWithCommas(_vm.currentBalanceCr)
                            : 0.0
                        ) +
                        " Dr\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "col-sm-4" }, [
                    _vm._v(
                      "\n              ₹ " +
                        _vm._s(
                          _vm.currentBalanceDr
                            ? _vm.numberWithCommas(_vm.currentBalanceDr)
                            : 0.0
                        ) +
                        " Cr\n            "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _c("h6", { staticClass: "row p-footer" }, [
                  _c("span", { staticClass: "col-sm-4" }, [
                    _vm._v("Closing Balance:"),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "col-sm-4" }, [
                    _vm._v(
                      "\n              ₹ " +
                        _vm._s(
                          _vm.closingBalanceCr
                            ? _vm.numberWithCommas(_vm.closingBalanceCr)
                            : 0.0
                        ) +
                        " Dr\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "col-sm-4" }, [
                    _vm._v(
                      "\n              ₹ " +
                        _vm._s(
                          _vm.closingBalanceDr
                            ? _vm.numberWithCommas(_vm.closingBalanceDr)
                            : 0.0
                        ) +
                        " Cr\n            "
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }