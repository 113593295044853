var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "grid-paginate" }, [
    _c("div", { staticClass: "grid-paginate-item grid-page-count" }, [
      _c("span", { staticClass: "grid-paginate-item" }, [_vm._v("Show")]),
      _vm._v(" "),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model.number",
              value: _vm.count,
              expression: "count",
              modifiers: { number: true },
            },
          ],
          domProps: { value: _vm.pageCount },
          on: {
            change: function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return _vm._n(val)
                })
              _vm.count = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            },
          },
        },
        [
          _c("option", { attrs: { value: "10" } }, [_vm._v("10")]),
          _vm._v(" "),
          _c("option", { attrs: { value: "20" } }, [_vm._v("20")]),
          _vm._v(" "),
          _c("option", { attrs: { value: "30" } }, [_vm._v("30")]),
          _vm._v(" "),
          _c("option", { attrs: { value: "40" } }, [_vm._v("40")]),
          _vm._v(" "),
          _c("option", { attrs: { value: "50" } }, [_vm._v("50")]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("span", { staticClass: "grid-paginate-item grid-page-current" }, [
      _vm._v("Page " + _vm._s(_vm.page + 1) + " of " + _vm._s(_vm.pages)),
    ]),
    _c("span", { staticClass: "grid-paginate-item grid-page-rows" }, [
      _vm._v("Rows: " + _vm._s(_vm.totalRows)),
    ]),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "grid-paginate-item grid-paginate-button",
        attrs: { type: "button", disabled: _vm.page === 0 },
        on: { click: _vm.prev },
      },
      [_vm._v("Prev")]
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "grid-paginate-item grid-paginate-button",
        attrs: { type: "button", disabled: _vm.page + 1 === _vm.pages },
        on: { click: _vm.next },
      },
      [_vm._v("Next")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }