var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "user-create main-content" }, [
    _c(
      "form",
      {
        attrs: { action: "" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submitUserData.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "page-header" }, [
          _c("h3", { staticClass: "page-title" }, [
            _vm._v(
              _vm._s(
                _vm.isEdit
                  ? _vm.$t("users.edit_user")
                  : _vm.$t("users.new_user")
              )
            ),
          ]),
          _vm._v(" "),
          _c("ol", { staticClass: "breadcrumb" }, [
            _c(
              "li",
              { staticClass: "breadcrumb-item" },
              [
                _c(
                  "router-link",
                  {
                    attrs: { slot: "item-title", to: "/invoices" },
                    slot: "item-title",
                  },
                  [_vm._v(_vm._s(_vm.$t("general.home")))]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "breadcrumb-item" },
              [
                _c(
                  "router-link",
                  {
                    attrs: { slot: "item-title", to: "/users" },
                    slot: "item-title",
                  },
                  [_vm._v(_vm._s(_vm.$tc("users.user", 2)))]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("li", { staticClass: "breadcrumb-item" }, [
              _vm._v(
                _vm._s(
                  _vm.isEdit
                    ? _vm.$t("users.edit_user")
                    : _vm.$t("users.new_user")
                )
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "user-card card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-md-6 mb-4 form-group" },
                [
                  _c("label", { staticClass: "input-label" }, [
                    _vm._v(_vm._s(_vm.$tc("users.name"))),
                  ]),
                  _c("span", { staticClass: "text-danger" }, [_vm._v(" * ")]),
                  _vm._v(" "),
                  _c("base-input", {
                    attrs: {
                      invalid: _vm.$v.formData.name.$error,
                      placeholder: _vm.$t("users.name"),
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.formData.name.$touch()
                      },
                    },
                    model: {
                      value: _vm.formData.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "name", $$v)
                      },
                      expression: "formData.name",
                    },
                  }),
                  _vm._v(" "),
                  _vm.$v.formData.name.$error
                    ? _c("div", [
                        !_vm.$v.formData.name.required
                          ? _c("span", { staticClass: "text-danger" }, [
                              _vm._v(_vm._s(_vm.$tc("validation.required"))),
                            ])
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-6 mb-4 form-group" },
                [
                  _c("label", { staticClass: "input-label" }, [
                    _vm._v(_vm._s(_vm.$tc("users.email"))),
                  ]),
                  _c("span", { staticClass: "text-danger" }, [_vm._v(" * ")]),
                  _vm._v(" "),
                  _c("base-input", {
                    attrs: {
                      invalid: _vm.$v.formData.email.$error,
                      placeholder: _vm.$t("users.email"),
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.formData.email.$touch()
                      },
                    },
                    model: {
                      value: _vm.formData.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "email", $$v)
                      },
                      expression: "formData.email",
                    },
                  }),
                  _vm._v(" "),
                  _vm.$v.formData.email.$error
                    ? _c("div", [
                        !_vm.$v.formData.email.required
                          ? _c("span", { staticClass: "text-danger" }, [
                              _vm._v(_vm._s(_vm.$tc("validation.required"))),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.$v.formData.email.email
                          ? _c("span", { staticClass: "text-danger" }, [
                              _vm._v(
                                _vm._s(_vm.$tc("validation.email_incorrect"))
                              ),
                            ])
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-6 mb-4 form-group" },
                [
                  _c("label", { staticClass: "input-label" }, [
                    _vm._v(_vm._s(_vm.$tc("users.password"))),
                  ]),
                  _c("span", { staticClass: "text-danger" }, [_vm._v(" * ")]),
                  _vm._v(" "),
                  _c("base-input", {
                    attrs: {
                      invalid: _vm.$v.formData.password.$error,
                      placeholder: !_vm.isEdit
                        ? _vm.$t("users.password")
                        : _vm.$t("users.change_password"),
                      type: "password",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.formData.password.$touch()
                      },
                    },
                    model: {
                      value: _vm.formData.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "password", $$v)
                      },
                      expression: "formData.password",
                    },
                  }),
                  _vm._v(" "),
                  _vm.$v.formData.password.$error
                    ? _c("div", [
                        !_vm.$v.formData.password.minLength
                          ? _c("span", { staticClass: "text-danger" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$tc(
                                      "validation.password_min_length",
                                      _vm.$v.formData.password.$params.minLength
                                        .min,
                                      {
                                        count:
                                          _vm.$v.formData.password.$params
                                            .minLength.min,
                                      }
                                    )
                                  ) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-6 mb-4 form-group" },
                [
                  _c("label", { staticClass: "input-label" }, [
                    _vm._v(_vm._s(_vm.$tc("users.confirm_password"))),
                  ]),
                  _c("span", { staticClass: "text-danger" }, [_vm._v(" * ")]),
                  _vm._v(" "),
                  _c("base-input", {
                    attrs: {
                      invalid: _vm.$v.formData.confirm_password.$error,
                      placeholder: !_vm.isEdit
                        ? _vm.$t("users.confirm_password")
                        : _vm.$t("users.change_confirm_password"),
                      type: "password",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.formData.confirm_password.$touch()
                      },
                    },
                    model: {
                      value: _vm.formData.confirm_password,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "confirm_password", $$v)
                      },
                      expression: "formData.confirm_password",
                    },
                  }),
                  _vm._v(" "),
                  _vm.$v.formData.confirm_password.$error
                    ? _c("div", [
                        !_vm.$v.formData.confirm_password.sameAsPassword
                          ? _c("span", { staticClass: "text-danger" }, [
                              _vm._v(
                                _vm._s(_vm.$tc("validation.password_incorrect"))
                              ),
                            ])
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-6 mb-4 form-group" },
                [
                  _c("label", { staticClass: "input-label" }, [
                    _vm._v(_vm._s(_vm.$tc("users.company_name"))),
                  ]),
                  _c("span", { staticClass: "text-danger" }, [_vm._v(" * ")]),
                  _vm._v(" "),
                  _c("base-select", {
                    class: { error: _vm.$v.formData.company.$error },
                    attrs: {
                      options: _vm.companies,
                      searchable: true,
                      "show-labels": false,
                      "allow-empty": false,
                      placeholder: _vm.$tc("users.companies"),
                      value: _vm.formData.company,
                      label: "name",
                      "track-by": "id",
                      name: "company",
                      id: "company",
                    },
                    model: {
                      value: _vm.companyBind,
                      callback: function ($$v) {
                        _vm.companyBind = $$v
                      },
                      expression: "companyBind",
                    },
                  }),
                  _vm._v(" "),
                  _vm.$v.formData.company.$error
                    ? _c("div", [
                        !_vm.$v.formData.company.required
                          ? _c("span", { staticClass: "text-danger" }, [
                              _vm._v(_vm._s(_vm.$tc("validation.required"))),
                            ])
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-6 mb-4 form-group" },
                [
                  _c("label", { staticClass: "input-label" }, [
                    _vm._v(_vm._s(_vm.$tc("users.role"))),
                  ]),
                  _c("span", { staticClass: "text-danger" }, [_vm._v(" * ")]),
                  _vm._v(" "),
                  _c("base-select", {
                    class: { error: _vm.$v.formData.role.$error },
                    attrs: {
                      options: _vm.roles,
                      searchable: true,
                      "show-labels": false,
                      "allow-empty": false,
                      placeholder: _vm.$tc("users.roles"),
                      value: _vm.formData.role,
                      label: "name",
                      "track-by": "id",
                      name: "role",
                      id: "role",
                    },
                    model: {
                      value: _vm.roleBind,
                      callback: function ($$v) {
                        _vm.roleBind = $$v
                      },
                      expression: "roleBind",
                    },
                  }),
                  _vm._v(" "),
                  _vm.$v.formData.role.$error
                    ? _c("div", [
                        !_vm.$v.formData.role.required
                          ? _c("span", { staticClass: "text-danger" }, [
                              _vm._v(_vm._s(_vm.$tc("validation.required"))),
                            ])
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "page-actions header-button-container" },
              [
                _c(
                  "base-button",
                  {
                    attrs: {
                      loading: _vm.isLoading,
                      disabled: _vm.isLoading,
                      tabindex: 23,
                      icon: "save",
                      color: "theme",
                      type: "submit",
                    },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.isEdit
                            ? _vm.$t("users.update_user")
                            : _vm.$t("users.save_user")
                        ) +
                        "\n          "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }