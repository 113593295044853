var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "receipt-create main-content" }, [
    _c("div", { staticClass: "page-header" }, [
      _c(
        "div",
        { staticClass: "page-actions row" },
        [
          _c(
            "router-link",
            {
              staticClass: "col-xs-2",
              attrs: { slot: "item-title", to: "/receipts" },
              slot: "item-title",
            },
            [
              _c(
                "base-button",
                { attrs: { size: "large", icon: "envelope", color: "theme" } },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("receipts.title")) +
                      "\n        "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c(
      "form",
      {
        attrs: { action: "" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submitReceiptData.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "page-header" }, [
          _c("h3", { staticClass: "page-title" }, [
            _vm._v(
              _vm._s(
                _vm.isEdit
                  ? _vm.$t("receipts.edit_receipt")
                  : _vm.$t("receipts.new_receipt")
              )
            ),
          ]),
          _vm._v(" "),
          _c("ol", { staticClass: "breadcrumb" }, [
            _c(
              "li",
              { staticClass: "breadcrumb-item" },
              [
                _c(
                  "router-link",
                  {
                    attrs: { slot: "item-title", to: "/invoices" },
                    slot: "item-title",
                  },
                  [_vm._v(_vm._s(_vm.$t("general.home")))]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "breadcrumb-item" },
              [
                _c(
                  "router-link",
                  {
                    attrs: { slot: "item-title", to: "/receipts" },
                    slot: "item-title",
                  },
                  [_vm._v(_vm._s(_vm.$tc("receipts.receipt", 2)))]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("li", { staticClass: "breadcrumb-item" }, [
              _vm._v(
                _vm._s(
                  _vm.isEdit
                    ? _vm.$t("receipts.edit_receipt")
                    : _vm.$t("receipts.new_receipt")
                )
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "receipt-card card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-6" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v(_vm._s(_vm.$t("receipts.date"))),
                    ]),
                    _c("span", { staticClass: "text-danger" }, [_vm._v(" *")]),
                    _vm._v(" "),
                    _c("base-date-picker", {
                      attrs: {
                        invalid: _vm.$v.formData.receipt_date.$error,
                        "calendar-button": true,
                        "calendar-button-icon": "calendar",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$v.formData.receipt_date.$touch()
                        },
                      },
                      model: {
                        value: _vm.formData.receipt_date,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "receipt_date", $$v)
                        },
                        expression: "formData.receipt_date",
                      },
                    }),
                    _vm._v(" "),
                    _vm.$v.formData.receipt_date.$error
                      ? _c("div", [
                          !_vm.$v.formData.receipt_date.required
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(_vm._s(_vm.$t("validation.required"))),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-6" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v(_vm._s(_vm.$t("receipts.receipt_number"))),
                    ]),
                    _c("span", { staticClass: "text-danger" }, [_vm._v(" *")]),
                    _vm._v(" "),
                    _c("base-prefix-input", {
                      attrs: {
                        invalid: _vm.$v.receiptNumAttribute.$error,
                        prefix: _vm.receiptPrefix,
                        disabled: _vm.isEdit,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$v.receiptNumAttribute.$touch()
                        },
                      },
                      model: {
                        value: _vm.receiptNumAttribute,
                        callback: function ($$v) {
                          _vm.receiptNumAttribute =
                            typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "receiptNumAttribute",
                      },
                    }),
                    _vm._v(" "),
                    _vm.$v.receiptNumAttribute.$error
                      ? _c("div", [
                          !_vm.$v.receiptNumAttribute.required
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(_vm._s(_vm.$tc("validation.required"))),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.$v.receiptNumAttribute.numeric
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  _vm._s(_vm.$tc("validation.numbers_only"))
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-sm-6" },
                [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v(_vm._s(_vm.$t("receipts.list"))),
                  ]),
                  _c("span", { staticClass: "text-danger" }, [_vm._v(" *")]),
                  _vm._v(" "),
                  _c("base-select", {
                    attrs: {
                      invalid: _vm.$v.formData.list.$error,
                      options: _vm.sundryDebtorList,
                      searchable: true,
                      "show-labels": false,
                      placeholder: _vm.$t("receipts.select_a_list"),
                      label: "name",
                      "track-by": "id",
                      disabled: _vm.isEdit,
                    },
                    model: {
                      value: _vm.formData.list,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "list", $$v)
                      },
                      expression: "formData.list",
                    },
                  }),
                  _vm._v(" "),
                  _vm.$v.formData.list.$error
                    ? _c("div", [
                        !_vm.$v.formData.list.required
                          ? _c("span", { staticClass: "text-danger" }, [
                              _vm._v(_vm._s(_vm.$tc("validation.required"))),
                            ])
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-6" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v(_vm._s(_vm.$t("receipts.amount"))),
                    ]),
                    _c("span", { staticClass: "text-danger" }, [_vm._v(" *")]),
                    _vm._v(" "),
                    _c("base-input", {
                      class: {
                        invalid: _vm.$v.formData.amount.$error,
                        "input-field": true,
                      },
                      attrs: { type: "number", max: 15, name: "amount" },
                      model: {
                        value: _vm.formData.amount,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData,
                            "amount",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formData.amount",
                      },
                    }),
                    _vm._v(" "),
                    _vm.formData.amount
                      ? _c("div", [
                          _vm._v(
                            "\n                ₹ " +
                              _vm._s(
                                _vm.numberWithCommas(_vm.formData.amount)
                              ) +
                              "\n              "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.$v.formData.amount.$error
                      ? _c("div", [
                          !_vm.$v.formData.amount.required
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(_vm._s(_vm.$t("validation.required"))),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-6" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v(_vm._s(_vm.$t("receipts.receipt_mode"))),
                    ]),
                    _c("span", { staticClass: "text-danger" }, [_vm._v(" *")]),
                    _vm._v(" "),
                    _c("base-select", {
                      class: { invalid: _vm.$v.formData.receipt_mode.$error },
                      attrs: {
                        options: _vm.getReceiptMode,
                        searchable: true,
                        "show-labels": false,
                        placeholder: _vm.$t("receipts.select_receipt_mode"),
                        disabled: _vm.isEdit,
                      },
                      model: {
                        value: _vm.formData.receipt_mode,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "receipt_mode", $$v)
                        },
                        expression: "formData.receipt_mode",
                      },
                    }),
                    _vm._v(" "),
                    _vm.$v.formData.receipt_mode.$error
                      ? _c("div", [
                          !_vm.$v.formData.receipt_mode.required
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(_vm._s(_vm.$tc("validation.required"))),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-6" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v(_vm._s(_vm.$t("receipts.note"))),
                    ]),
                    _vm._v(" "),
                    _c("base-text-area", {
                      attrs: { rows: "5" },
                      model: {
                        value: _vm.formData.notes,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "notes", $$v)
                        },
                        expression: "formData.notes",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-6" }, [
                _c("div", { staticClass: "col-sm-12 p-0" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v(_vm._s(_vm.$t("receipts.opening_balance"))),
                      ]),
                      _vm._v(" "),
                      _c("base-prefix-input", {
                        attrs: {
                          prefix: _vm.openingBalanceType
                            ? _vm.openingBalanceType + " - " + _vm.money.prefix
                            : _vm.money.prefix,
                          type: "number",
                          name: "openingBalance",
                          disabled: "",
                        },
                        model: {
                          value: _vm.openingBalance,
                          callback: function ($$v) {
                            _vm.openingBalance =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "openingBalance",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-12 p-0" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v(_vm._s(_vm.$t("receipts.closing_balance"))),
                      ]),
                      _vm._v(" "),
                      _c("base-prefix-input", {
                        attrs: {
                          prefix: _vm.closingBalanceType
                            ? _vm.closingBalanceType + " - " + _vm.money.prefix
                            : _vm.money.prefix,
                          type: "number",
                          name: "closingBalance",
                          disabled: "",
                        },
                        model: {
                          value: _vm.closingBalance,
                          callback: function ($$v) {
                            _vm.closingBalance =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "closingBalance",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-12" }, [
                _c(
                  "div",
                  { staticClass: "form-group collapse-button-container" },
                  [
                    _c(
                      "base-button",
                      {
                        staticClass: "collapse-button",
                        attrs: {
                          loading: _vm.isLoading,
                          icon: "save",
                          color: "theme",
                          type: "submit",
                        },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("receipts.save_receipt")) +
                            "\n              "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _vm.isEdit
                      ? _c(
                          "base-button",
                          {
                            staticClass: "report-button",
                            attrs: { outline: "", color: "theme" },
                            on: {
                              click: function ($event) {
                                return _vm.sendReports()
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$t("reports.send_report")) +
                                "\n              "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }