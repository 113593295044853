var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-content item-create" }, [
    _c("div", { staticClass: "page-header" }, [
      _c("h3", { staticClass: "page-title" }, [
        _vm._v(
          _vm._s(
            _vm.isEdit ? _vm.$t("notes.edit_note") : _vm.$t("notes.new_note")
          )
        ),
      ]),
      _vm._v(" "),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              {
                attrs: { slot: "item-title", to: "/invoices" },
                slot: "item-title",
              },
              [_vm._v(_vm._s(_vm.$t("general.home")))]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              {
                attrs: { slot: "item-title", to: "/notes" },
                slot: "item-title",
              },
              [_vm._v(_vm._s(_vm.$tc("notes.notes", 2)))]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item" }, [
          _c("a", { attrs: { href: "#" } }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.isEdit
                    ? _vm.$t("notes.edit_note")
                    : _vm.$t("notes.new_note")
                )
            ),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col col-12 col-md-12 col-lg-6" }, [
        _c("div", { staticClass: "card" }, [
          _c(
            "form",
            {
              attrs: { action: "" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submitNote.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "card-body", attrs: { id: "to_print" } },
                [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { staticClass: "control-label" }, [
                        _vm._v(_vm._s(_vm.$t("notes.name"))),
                      ]),
                      _c("span", { staticClass: "text-danger" }, [
                        _vm._v(" *"),
                      ]),
                      _vm._v(" "),
                      _c("base-input", {
                        attrs: {
                          invalid: _vm.$v.formData.name.$error,
                          focus: "",
                          type: "text",
                          name: "name",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$v.formData.name.$touch()
                          },
                        },
                        model: {
                          value: _vm.formData.name,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formData,
                              "name",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formData.name",
                        },
                      }),
                      _vm._v(" "),
                      _vm.$v.formData.name.$error
                        ? _c("div", [
                            !_vm.$v.formData.name.required
                              ? _c("span", { staticClass: "text-danger" }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("validation.required")) + " "
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.$v.formData.name.minLength
                              ? _c("span", { staticClass: "text-danger" }, [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm.$tc(
                                          "validation.name_min_length",
                                          _vm.$v.formData.name.$params.minLength
                                            .min,
                                          {
                                            count:
                                              _vm.$v.formData.name.$params
                                                .minLength.min,
                                          }
                                        )
                                      ) +
                                      "\n                "
                                  ),
                                ])
                              : _vm._e(),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { staticClass: "control-label" }, [
                        _vm._v(_vm._s(_vm.$t("notes.design_no"))),
                      ]),
                      _vm._v(" "),
                      _c("base-input", {
                        attrs: { focus: "", type: "text", name: "design_no" },
                        model: {
                          value: _vm.formData.design_no,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formData,
                              "design_no",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formData.design_no",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { staticClass: "control-label" }, [
                        _vm._v(_vm._s(_vm.$t("notes.rate"))),
                      ]),
                      _vm._v(" "),
                      _c("base-input", {
                        attrs: { focus: "", type: "text", name: "rate" },
                        model: {
                          value: _vm.formData.rate,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formData,
                              "rate",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formData.rate",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { staticClass: "control-label" }, [
                        _vm._v(_vm._s(_vm.$t("notes.average"))),
                      ]),
                      _vm._v(" "),
                      _c("base-input", {
                        attrs: { focus: "", type: "text", name: "average" },
                        model: {
                          value: _vm.formData.average,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formData,
                              "average",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formData.average",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { staticClass: "control-label" }, [
                        _vm._v(_vm._s(_vm.$t("notes.per_price"))),
                      ]),
                      _vm._v(" "),
                      _c("base-input", {
                        attrs: { focus: "", type: "text", name: "per_price" },
                        model: {
                          value: _vm.formData.per_price,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formData,
                              "per_price",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formData.per_price",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { staticClass: "control-label" }, [
                        _vm._v(_vm._s(_vm.$t("notes.note"))),
                      ]),
                      _vm._v(" "),
                      _c("base-text-area", {
                        attrs: { focus: "", type: "text", name: "note" },
                        model: {
                          value: _vm.formData.note,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formData,
                              "note",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formData.note",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("div", { staticClass: "fileUpload btn btn-default" }, [
                      _c("label", { staticClass: "upload mb-0" }, [
                        _c("input", {
                          attrs: {
                            type: "file",
                            accept: "image/*",
                            name: "image",
                          },
                          on: { change: _vm.uploadImage },
                        }),
                        _vm._v(
                          "\n                    Upload Photo\n                "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm.previewImage
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              width: "200px",
                              height: "auto",
                              "margin-top": "20px",
                            },
                          },
                          [
                            _c("img", {
                              staticClass: "uploading-image",
                              staticStyle: { width: "100%", height: "100%" },
                              attrs: { src: _vm.previewImage },
                            }),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.formData.image
                      ? _c("div", [
                          _c("a", {
                            staticStyle: { "font-size": "12px" },
                            attrs: {
                              href: _vm.formData.image,
                              target: "_blank",
                            },
                          }),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "base-button",
                        {
                          staticClass: "collapse-button",
                          attrs: {
                            id: "submit-note",
                            loading: _vm.isLoading,
                            disabled: _vm.isLoading,
                            icon: "save",
                            color: "theme",
                            type: "submit",
                          },
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.isEdit
                                  ? _vm.$t("notes.update_note")
                                  : _vm.$t("notes.save_note")
                              ) +
                              "\n              "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-lg btn-default print_no mt-2",
              attrs: { type: "button" },
              on: { click: _vm.printNote },
            },
            [_vm._v("Print")]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }