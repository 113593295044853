export const RESET_CURRENT_USER = 'RESET_CURRENT_USER'
export const BOOTSTRAP_CURRENT_USER = 'BOOTSTRAP_CURRENT_USER'
export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER'

export const BOOTSTRAP_USERS = 'BOOTSTRAP_USERS'
export const ADD_USER = 'ADD_USER'
export const UPDATE_USER = 'UPDATE_USER'
export const DELETE_USER = 'DELETE_USER'
export const DELETE_MULTIPLE_USERS = 'DELETE_MULTIPLE_USERS'
export const SET_SELECTED_USERS = 'SET_SELECTED_USERS'
export const SET_TOTAL_USERS = 'SET_TOTAL_USERS'
export const RESET_SELECTED_USER = 'RESET_SELECTED_USER'
export const SET_SELECT_ALL_STATE = 'SET_SELECT_ALL_STATE'
