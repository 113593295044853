var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-content item-create" }, [
    _c("div", { staticClass: "page-header" }, [
      _c("h3", { staticClass: "page-title" }, [
        _vm._v(_vm._s(_vm.$t("general.invoice_stock"))),
      ]),
      _vm._v(" "),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              {
                attrs: { slot: "item-title", to: "/invoices" },
                slot: "item-title",
              },
              [_vm._v(_vm._s(_vm.$t("general.home")))]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              {
                attrs: { slot: "item-title", to: "/inventory" },
                slot: "item-title",
              },
              [_vm._v(_vm._s(_vm.$tc("inventory.inventory", 2)))]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              {
                attrs: { slot: "item-title", to: "/inventory/stock" },
                slot: "item-title",
              },
              [_vm._v(_vm._s(_vm.$tc("general.inventory_stock", 2)))]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item" }, [
          _c("a", { attrs: { href: "#" } }, [
            _vm._v(" " + _vm._s(_vm.$t("general.invoice_stock"))),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _vm.inventoryItems.length
      ? _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-sm-12 mb-2 print" },
            [
              _c(
                "base-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.inventoryItems,
                      expression: "inventoryItems",
                    },
                  ],
                  attrs: {
                    outline: true,
                    icon: ["fas", "print"],
                    color: "theme",
                    size: "large",
                    "right-icon": "",
                  },
                  on: { click: _vm.print },
                },
                [_vm._v("\n          Print\n        ")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "col col-12 col-md-12 col-lg-12" }, [
            _c("div", { staticClass: "card" }, [
              _c("h5", { staticClass: "p-3" }, [_vm._v("Inwards")]),
              _vm._v(" "),
              _c(
                "table",
                { staticClass: "p-3 m-3" },
                [
                  _vm._m(0),
                  _vm._v(" "),
                  _vm._l(_vm.inventoryItems, function (each, index) {
                    return _c(
                      "tr",
                      {
                        key: index,
                        staticStyle: { "border-top": "1px solid" },
                      },
                      [
                        _c("td", [_vm._v(_vm._s(each.name))]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(each.worker_name ? each.worker_name : "-")
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(each.quantity))]),
                        _vm._v(" "),
                        _c("td", [_vm._v("₹ " + _vm._s(each.sale_price))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(each.unit))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(each.date_time))]),
                      ]
                    )
                  }),
                ],
                2
              ),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col col-12 col-md-12 col-lg-12" }, [
        _c("div", { staticClass: "card" }, [
          _c("h5", { staticClass: "p-3" }, [_vm._v("Outwards")]),
          _vm._v(" "),
          _vm.invoiceItems.length
            ? _c(
                "table",
                { staticClass: "p-3 m-3" },
                [
                  _vm._m(1),
                  _vm._v(" "),
                  _vm._l(_vm.invoiceItems, function (each, index) {
                    return _c(
                      "tr",
                      {
                        key: index,
                        staticStyle: { "border-top": "1px solid" },
                      },
                      [
                        _c("td", [
                          _c(
                            "a",
                            {
                              staticStyle: { color: "blue" },
                              attrs: {
                                href: `/invoices/${each.invoice_id}/edit`,
                              },
                            },
                            [_vm._v(_vm._s(each.invoice_number))]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(each.party_name))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(each.quantity))]),
                        _vm._v(" "),
                        _c("td", [_vm._v("₹ " + _vm._s(each.sale_price))]),
                        _vm._v(" "),
                        _c("td", [_vm._v("₹ " + _vm._s(each.total))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(each.date_time))]),
                      ]
                    )
                  }),
                ],
                2
              )
            : _c("table", [
                _c("p", { staticClass: "ml-4" }, [
                  _vm._v("No invoice item found with this inventory"),
                ]),
              ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", [_vm._v("Item")]),
      _vm._v(" "),
      _c("th", [_vm._v("Worker Name")]),
      _vm._v(" "),
      _c("th", [_vm._v("Quantity")]),
      _vm._v(" "),
      _c("th", [_vm._v("Sale Price")]),
      _vm._v(" "),
      _c("th", [_vm._v("Unit")]),
      _vm._v(" "),
      _c("th", [_vm._v("Date/Time")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", [_vm._v("Invoice Number")]),
      _vm._v(" "),
      _c("th", [_vm._v("Party Name")]),
      _vm._v(" "),
      _c("th", [_vm._v("Quantity")]),
      _vm._v(" "),
      _c("th", [_vm._v("Sale Price")]),
      _vm._v(" "),
      _c("th", [_vm._v("Total")]),
      _vm._v(" "),
      _c("th", [_vm._v("Date/Time")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }