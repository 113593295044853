var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "setting-main-container customization" }, [
    _c(
      "div",
      { staticClass: "card setting-card" },
      [
        _c("ul", { staticClass: "tabs" }, [
          _c(
            "li",
            {
              staticClass: "tab",
              on: {
                click: function ($event) {
                  return _vm.setActiveTab("INVOICES")
                },
              },
            },
            [
              _c(
                "a",
                {
                  class: [
                    "tab-link",
                    { "a-active": _vm.activeTab === "INVOICES" },
                  ],
                  attrs: { href: "#" },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("settings.customization.invoices.title"))
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              staticClass: "tab",
              on: {
                click: function ($event) {
                  return _vm.setActiveTab("REFERENCES")
                },
              },
            },
            [
              _c(
                "a",
                {
                  class: [
                    "tab-link",
                    { "a-active": _vm.activeTab === "REFERENCES" },
                  ],
                  attrs: { href: "#" },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("settings.customization.references.title"))
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              staticClass: "tab",
              on: {
                click: function ($event) {
                  return _vm.setActiveTab("ESTIMATES")
                },
              },
            },
            [
              _c(
                "a",
                {
                  class: [
                    "tab-link",
                    { "a-active": _vm.activeTab === "ESTIMATES" },
                  ],
                  attrs: { href: "#" },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("settings.customization.estimates.title"))
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              staticClass: "tab",
              on: {
                click: function ($event) {
                  return _vm.setActiveTab("PAYMENTS")
                },
              },
            },
            [
              _c(
                "a",
                {
                  class: [
                    "tab-link",
                    { "a-active": _vm.activeTab === "PAYMENTS" },
                  ],
                  attrs: { href: "#" },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("settings.customization.payments.title"))
                  ),
                ]
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("transition", { attrs: { name: "fade-customize" } }, [
          _vm.activeTab === "INVOICES"
            ? _c("div", { staticClass: "invoice-tab" }, [
                _c(
                  "form",
                  {
                    staticClass: "form-section",
                    attrs: { action: "" },
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.updateInvoiceSetting.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-12 mb-4" },
                        [
                          _c("label", { staticClass: "input-label" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "settings.customization.invoices.invoice_prefix"
                                )
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("base-input", {
                            attrs: {
                              invalid: _vm.$v.invoices.invoice_prefix.$error,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.invoices.invoice_prefix.$touch()
                              },
                              keyup: function ($event) {
                                return _vm.changeToUppercase("INVOICES")
                              },
                            },
                            model: {
                              value: _vm.invoices.invoice_prefix,
                              callback: function ($$v) {
                                _vm.$set(_vm.invoices, "invoice_prefix", $$v)
                              },
                              expression: "invoices.invoice_prefix",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    !_vm.$v.invoices.invoice_prefix.required,
                                  expression:
                                    "!$v.invoices.invoice_prefix.required",
                                },
                              ],
                              staticClass: "text-danger mt-1",
                            },
                            [_vm._v(_vm._s(_vm.$t("validation.required")))]
                          ),
                          _vm._v(" "),
                          !_vm.$v.invoices.invoice_prefix.maxLength
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("validation.prefix_maxlength"))
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row mb-3" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-12" },
                        [
                          _c(
                            "base-button",
                            {
                              attrs: {
                                icon: "save",
                                color: "theme",
                                type: "submit",
                              },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.$t("settings.customization.save")
                                  ) +
                                  "\n              "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("hr"),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-12 mt-3" }, [
                  _c("div", { staticClass: "page-header" }, [
                    _c("h3", { staticClass: "page-title" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$t(
                              "settings.customization.invoices.invoice_settings"
                            )
                          ) +
                          "\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex-box" }, [
                      _c(
                        "div",
                        { staticClass: "left" },
                        [
                          _c("base-switch", {
                            staticClass: "btn-switch",
                            on: { change: _vm.setInvoiceSetting },
                            model: {
                              value: _vm.invoiceAutogenerate,
                              callback: function ($$v) {
                                _vm.invoiceAutogenerate = $$v
                              },
                              expression: "invoiceAutogenerate",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "right ml-15" }, [
                        _c("p", { staticClass: "box-title" }, [
                          _vm._v(
                            "  " +
                              _vm._s(
                                _vm.$t(
                                  "settings.customization.invoices.autogenerate_invoice_number"
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "box-desc" }, [
                          _vm._v(
                            "  " +
                              _vm._s(
                                _vm.$t(
                                  "settings.customization.invoices.invoice_setting_description"
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("transition", { attrs: { name: "fade-customize" } }, [
          _vm.activeTab === "REFERENCES"
            ? _c("div", { staticClass: "reference-tab" }, [
                _c(
                  "form",
                  {
                    staticClass: "form-section",
                    attrs: { action: "" },
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.updateReferenceSetting.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-12 mb-4" },
                        [
                          _c("label", { staticClass: "input-label" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "settings.customization.references.reference_prefix"
                                )
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("base-input", {
                            attrs: {
                              invalid:
                                _vm.$v.references.reference_prefix.$error,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.references.reference_prefix.$touch()
                              },
                              keyup: function ($event) {
                                return _vm.changeToUppercase("REFERENCES")
                              },
                            },
                            model: {
                              value: _vm.references.reference_prefix,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.references,
                                  "reference_prefix",
                                  $$v
                                )
                              },
                              expression: "references.reference_prefix",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    !_vm.$v.references.reference_prefix
                                      .required,
                                  expression:
                                    "!$v.references.reference_prefix.required",
                                },
                              ],
                              staticClass: "text-danger mt-1",
                            },
                            [_vm._v(_vm._s(_vm.$t("validation.required")))]
                          ),
                          _vm._v(" "),
                          !_vm.$v.references.reference_prefix.maxLength
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("validation.prefix_maxlength"))
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row mb-3" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-12" },
                        [
                          _c(
                            "base-button",
                            {
                              attrs: {
                                icon: "save",
                                color: "theme",
                                type: "submit",
                              },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.$t("settings.customization.save")
                                  ) +
                                  "\n              "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("hr"),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-12 mt-3" }, [
                  _c("div", { staticClass: "page-header" }, [
                    _c("h3", { staticClass: "page-title" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$t(
                              "settings.customization.references.reference_settings"
                            )
                          ) +
                          "\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex-box" }, [
                      _c(
                        "div",
                        { staticClass: "left" },
                        [
                          _c("base-switch", {
                            staticClass: "btn-switch",
                            on: { change: _vm.setReferenceSetting },
                            model: {
                              value: _vm.referenceAutogenerate,
                              callback: function ($$v) {
                                _vm.referenceAutogenerate = $$v
                              },
                              expression: "referenceAutogenerate",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "right ml-15" }, [
                        _c("p", { staticClass: "box-title" }, [
                          _vm._v(
                            "  " +
                              _vm._s(
                                _vm.$t(
                                  "settings.customization.references.autogenerate_reference_number"
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "box-desc" }, [
                          _vm._v(
                            "  " +
                              _vm._s(
                                _vm.$t(
                                  "settings.customization.references.reference_setting_description"
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("transition", { attrs: { name: "fade-customize" } }, [
          _vm.activeTab === "ESTIMATES"
            ? _c("div", { staticClass: "estimate-tab" }, [
                _c(
                  "form",
                  {
                    staticClass: "form-section",
                    attrs: { action: "" },
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.updateEstimateSetting.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-12 mb-4" },
                        [
                          _c("label", { staticClass: "input-label" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "settings.customization.estimates.estimate_prefix"
                                )
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("base-input", {
                            attrs: {
                              invalid: _vm.$v.estimates.estimate_prefix.$error,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.estimates.estimate_prefix.$touch()
                              },
                              keyup: function ($event) {
                                return _vm.changeToUppercase("ESTIMATES")
                              },
                            },
                            model: {
                              value: _vm.estimates.estimate_prefix,
                              callback: function ($$v) {
                                _vm.$set(_vm.estimates, "estimate_prefix", $$v)
                              },
                              expression: "estimates.estimate_prefix",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    !_vm.$v.estimates.estimate_prefix.required,
                                  expression:
                                    "!$v.estimates.estimate_prefix.required",
                                },
                              ],
                              staticClass: "text-danger mt-1",
                            },
                            [_vm._v(_vm._s(_vm.$t("validation.required")))]
                          ),
                          _vm._v(" "),
                          !_vm.$v.estimates.estimate_prefix.maxLength
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("validation.prefix_maxlength"))
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.$v.estimates.estimate_prefix.alpha
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("validation.characters_only"))
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row mb-3" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-12" },
                        [
                          _c(
                            "base-button",
                            {
                              attrs: {
                                icon: "save",
                                color: "theme",
                                type: "submit",
                              },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.$t("settings.customization.save")
                                  ) +
                                  "\n              "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("hr"),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-12 mt-3" }, [
                  _c("div", { staticClass: "page-header" }, [
                    _c("h3", { staticClass: "page-title" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$t(
                              "settings.customization.estimates.estimate_settings"
                            )
                          ) +
                          "\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex-box" }, [
                      _c(
                        "div",
                        { staticClass: "left" },
                        [
                          _c("base-switch", {
                            staticClass: "btn-switch",
                            on: { change: _vm.setEstimateSetting },
                            model: {
                              value: _vm.estimateAutogenerate,
                              callback: function ($$v) {
                                _vm.estimateAutogenerate = $$v
                              },
                              expression: "estimateAutogenerate",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "right ml-15" }, [
                        _c("p", { staticClass: "box-title" }, [
                          _vm._v(
                            "  " +
                              _vm._s(
                                _vm.$t(
                                  "settings.customization.estimates.autogenerate_estimate_number"
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "box-desc" }, [
                          _vm._v(
                            "  " +
                              _vm._s(
                                _vm.$t(
                                  "settings.customization.estimates.estimate_setting_description"
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("transition", { attrs: { name: "fade-customize" } }, [
          _vm.activeTab === "PAYMENTS"
            ? _c("div", { staticClass: "payment-tab" }, [
                _c(
                  "form",
                  {
                    staticClass: "form-section",
                    attrs: { action: "" },
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.updatePaymentSetting.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-12 mb-4" },
                        [
                          _c("label", { staticClass: "input-label" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "settings.customization.payments.payment_prefix"
                                )
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("base-input", {
                            attrs: {
                              invalid: _vm.$v.payments.payment_prefix.$error,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.payments.payment_prefix.$touch()
                              },
                              keyup: function ($event) {
                                return _vm.changeToUppercase("PAYMENTS")
                              },
                            },
                            model: {
                              value: _vm.payments.payment_prefix,
                              callback: function ($$v) {
                                _vm.$set(_vm.payments, "payment_prefix", $$v)
                              },
                              expression: "payments.payment_prefix",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    !_vm.$v.payments.payment_prefix.required,
                                  expression:
                                    "!$v.payments.payment_prefix.required",
                                },
                              ],
                              staticClass: "text-danger mt-1",
                            },
                            [_vm._v(_vm._s(_vm.$t("validation.required")))]
                          ),
                          _vm._v(" "),
                          !_vm.$v.payments.payment_prefix.maxLength
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("validation.prefix_maxlength"))
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.$v.payments.payment_prefix.alpha
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("validation.characters_only"))
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row mb-3" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-12" },
                        [
                          _c(
                            "base-button",
                            {
                              attrs: {
                                icon: "save",
                                color: "theme",
                                type: "submit",
                              },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.$t("settings.customization.save")
                                  ) +
                                  "\n              "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-12 mt-4" }, [
                  _c("div", { staticClass: "page-header" }, [
                    _c("h3", { staticClass: "page-title" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$t(
                              "settings.customization.payments.payment_settings"
                            )
                          ) +
                          "\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex-box" }, [
                      _c(
                        "div",
                        { staticClass: "left" },
                        [
                          _c("base-switch", {
                            staticClass: "btn-switch",
                            on: { change: _vm.setPaymentSetting },
                            model: {
                              value: _vm.paymentAutogenerate,
                              callback: function ($$v) {
                                _vm.paymentAutogenerate = $$v
                              },
                              expression: "paymentAutogenerate",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "right ml-15" }, [
                        _c("p", { staticClass: "box-title" }, [
                          _vm._v(
                            "  " +
                              _vm._s(
                                _vm.$t(
                                  "settings.customization.payments.autogenerate_payment_number"
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "box-desc" }, [
                          _vm._v(
                            "  " +
                              _vm._s(
                                _vm.$t(
                                  "settings.customization.payments.payment_setting_description"
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }