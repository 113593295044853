var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      attrs: { id: "loginForm" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.validateBeforeSubmit.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        { class: { "form-group": true } },
        [
          _c("p", { staticClass: "input-label" }, [
            _vm._v(_vm._s(_vm.$t("login.email")) + " "),
            _c("span", { staticClass: "text-danger" }, [_vm._v(" * ")]),
          ]),
          _vm._v(" "),
          _c("base-input", {
            attrs: {
              invalid: _vm.$v.loginData.email.$error,
              placeholder: _vm.$t(_vm.login.login_placeholder),
              focus: "",
              type: "email",
              name: "email",
              id: "login-id",
            },
            on: {
              input: function ($event) {
                return _vm.$v.loginData.email.$touch()
              },
              change: function ($event) {
                _vm.isError = false
              },
            },
            model: {
              value: _vm.loginData.email,
              callback: function ($$v) {
                _vm.$set(_vm.loginData, "email", $$v)
              },
              expression: "loginData.email",
            },
          }),
          _vm._v(" "),
          _vm.$v.loginData.email.$error
            ? _c("div", [
                !_vm.$v.loginData.email.required
                  ? _c("span", { staticClass: "text-danger" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$tc("validation.required")) +
                          "\n      "
                      ),
                    ])
                  : !_vm.$v.loginData.email.email
                  ? _c("span", { staticClass: "text-danger" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$tc("validation.email_incorrect")) +
                          "\n      "
                      ),
                    ])
                  : _vm._e(),
              ])
            : _vm.isError
            ? _c("div", [
                _c("span", { staticClass: "text-danger" }, [
                  _vm._v("\n        " + _vm._s(_vm.customError) + "\n      "),
                ]),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("p", { staticClass: "input-label" }, [
            _vm._v(_vm._s(_vm.$t("login.password")) + " "),
            _c("span", { staticClass: "text-danger" }, [_vm._v(" * ")]),
          ]),
          _vm._v(" "),
          _c("base-input", {
            attrs: {
              invalid: _vm.$v.loginData.password.$error,
              type: "password",
              name: "password",
              "show-password": "",
              id: "login-password",
            },
            on: {
              input: function ($event) {
                return _vm.$v.loginData.password.$touch()
              },
              change: function ($event) {
                _vm.isError = false
              },
            },
            model: {
              value: _vm.loginData.password,
              callback: function ($$v) {
                _vm.$set(_vm.loginData, "password", $$v)
              },
              expression: "loginData.password",
            },
          }),
          _vm._v(" "),
          _vm.$v.loginData.password.$error
            ? _c("div", [
                !_vm.$v.loginData.password.required
                  ? _c("span", { staticClass: "text-danger" }, [
                      _vm._v(_vm._s(_vm.$tc("validation.required"))),
                    ])
                  : !_vm.$v.loginData.password.minLength
                  ? _c("span", { staticClass: "text-danger" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$tc(
                              "validation.password_min_length",
                              _vm.$v.loginData.password.$params.minLength.min,
                              {
                                count:
                                  _vm.$v.loginData.password.$params.minLength
                                    .min,
                              }
                            )
                          ) +
                          " "
                      ),
                    ])
                  : _vm._e(),
              ])
            : _vm.isError
            ? _c("div", [
                _c("span", { staticClass: "text-danger" }, [
                  _vm._v("\n        " + _vm._s(_vm.customError) + "\n      "),
                ]),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "other-actions row" }, [
        _c(
          "div",
          { staticClass: "col-sm-12 text-sm-left mb-4" },
          [
            _c(
              "router-link",
              { staticClass: "forgot-link", attrs: { to: "forgot-password" } },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("login.forgot_password")) +
                    "\n      "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("base-button", { attrs: { type: "submit", color: "theme" } }, [
        _vm._v(_vm._s(_vm.$t("login.login"))),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }