var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-content item-create" }, [
    _c("div", { staticClass: "page-header" }, [
      _c("h3", { staticClass: "page-title" }, [
        _vm._v(
          _vm._s(
            _vm.isEdit
              ? _vm.$t("dispatch.edit_dispatch")
              : _vm.$t("dispatch.new_dispatch")
          )
        ),
      ]),
      _vm._v(" "),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              {
                attrs: { slot: "item-title", to: "/invoices" },
                slot: "item-title",
              },
              [_vm._v(_vm._s(_vm.$t("general.home")))]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              {
                attrs: { slot: "item-title", to: "/dispatch" },
                slot: "item-title",
              },
              [_vm._v(_vm._s(_vm.$tc("dispatch.dispatch", 2)))]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item" }, [
          _c("a", { attrs: { href: "#" } }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.isEdit
                    ? _vm.$t("dispatch.edit_dispatch")
                    : _vm.$t("dispatch.new_dispatch")
                )
            ),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col col-12 col-md-12 col-lg-6" }, [
        _c("div", { staticClass: "card" }, [
          _c(
            "form",
            {
              attrs: { action: "" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submitDispatch.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "card-body", attrs: { id: "to_print" } },
                [
                  _vm.invoiceList &&
                  _vm.invoiceList.length &&
                  !_vm.change_invoice
                    ? _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { staticClass: "form-label" }, [
                            _vm._v(_vm._s(_vm.$t("receipts.invoice"))),
                          ]),
                          _vm._v(" "),
                          _c("base-select", {
                            staticClass: "multi-select-item",
                            attrs: {
                              multiple: true,
                              "show-pointer": false,
                              options: _vm.isEdit
                                ? _vm.invoiceList
                                : _vm.invoiceList.filter(
                                    (node) => node.status !== "COMPLETED"
                                  ),
                              searchable: true,
                              "show-labels": false,
                              "allow-empty": true,
                              disabled: _vm.isEdit,
                              "custom-label": _vm.invoiceWithAmount,
                              "track-by": "invoice_number",
                            },
                            on: {
                              select: _vm.addInvoice,
                              remove: _vm.removeInvoice,
                            },
                            model: {
                              value: _vm.invoice,
                              callback: function ($$v) {
                                _vm.invoice = $$v
                              },
                              expression: "invoice",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.change_invoice
                    ? _c(
                        "div",
                        { staticClass: "form-group" },
                        _vm._l(_vm.filterInvoice, function (value, index) {
                          return _c(
                            "span",
                            { key: index, staticClass: "ml-2" },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    "(" +
                                      value.data[0].invoice_number +
                                      " - " +
                                      "(" +
                                      value.data[0].master.name +
                                      ")" +
                                      " * " +
                                      value.count +
                                      ")"
                                  ) +
                                  "\n              "
                              ),
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { staticClass: "control-label" }, [
                        _vm._v(_vm._s(_vm.$t("dispatch.date_time"))),
                      ]),
                      _c("span", { staticClass: "text-danger" }, [
                        _vm._v(" *"),
                      ]),
                      _vm._v(" "),
                      _c("base-date-picker", {
                        attrs: {
                          format: "Y-m-d",
                          invalid: _vm.$v.formData.date_time.$error,
                          "calendar-button": true,
                          "calendar-button-icon": "calendar",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$v.formData.date_time.$touch()
                          },
                        },
                        model: {
                          value: _vm.formData.date_time,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "date_time", $$v)
                          },
                          expression: "formData.date_time",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v(_vm._s(_vm.$t("dispatch.time"))),
                    ]),
                    _c("span", { staticClass: "text-danger" }, [_vm._v(" *")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "base-date-input" },
                      [
                        _c("vue-timepicker", {
                          attrs: {
                            format: "hh:mm A",
                            "hide-clear-button": true,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$v.formData.time.$touch()
                            },
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "icon",
                              fn: function () {
                                return [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "vdp-datepicker__calendar-button input-group-prepend",
                                    },
                                    [
                                      _c(
                                        "span",
                                        [
                                          _c("font-awesome-icon", {
                                            attrs: {
                                              id: "time-icon",
                                              icon: ["fas", "clock"],
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                          model: {
                            value: _vm.formData.time,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "time", $$v)
                            },
                            expression: "formData.time",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { staticClass: "control-label" }, [
                        _vm._v(_vm._s(_vm.$t("dispatch.person"))),
                      ]),
                      _vm._v(" "),
                      _c("base-input", {
                        attrs: { focus: "", type: "text", name: "person" },
                        model: {
                          value: _vm.formData.person,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formData,
                              "person",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formData.person",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { staticClass: "control-label" }, [
                        _vm._v(_vm._s(_vm.$t("dispatch.transport"))),
                      ]),
                      _vm._v(" "),
                      _c("base-input", {
                        attrs: { focus: "", type: "text", name: "transport" },
                        model: {
                          value: _vm.formData.transport,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formData,
                              "transport",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formData.transport",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "base-button",
                        {
                          staticClass: "collapse-button",
                          attrs: {
                            id: "submit-dispatch",
                            loading: _vm.isLoading,
                            disabled: _vm.isLoading,
                            icon: "save",
                            color: "theme",
                            type: "submit",
                          },
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.isEdit
                                  ? _vm.$t("dispatch.update_dispatch")
                                  : _vm.$t("dispatch.save_dispatch")
                              ) +
                              "\n              "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }