var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "d-flex flex-row flex-wrap justify-content-between align-items-center",
    },
    [
      _c("div", { staticClass: "d-flex flex-column" }, [
        _c("h3", { staticClass: "page-title" }, [_vm._v(_vm._s(_vm.title))]),
        _vm._v(" "),
        _c(
          "ol",
          { staticClass: "breadcrumb" },
          _vm._l(_vm.breadCrumbLinks, function (link, index) {
            return _c(
              "li",
              { key: index, staticClass: "breadcrumb-item" },
              [
                _c(
                  "router-link",
                  {
                    attrs: { slot: "item-title", to: link.url },
                    slot: "item-title",
                  },
                  [
                    _vm._v(
                      "\n              " + _vm._s(link.title) + "\n            "
                    ),
                  ]
                ),
              ],
              1
            )
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex flex-wrap" }, [_vm._t("default")], 2),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }