export const RESET_CURRENT_NOTE = 'RESET_CURRENT_NOTE'
export const BOOTSTRAP_CURRENT_NOTE = 'BOOTSTRAP_CURRENT_NOTE'
export const UPDATE_CURRENT_NOTE = 'UPDATE_CURRENT_NOTE'

export const BOOTSTRAP_NOTES = 'BOOTSTRAP_NOTES'
export const ADD_NOTE = 'ADD_NOTE'
export const UPDATE_NOTE = 'UPDATE_NOTE'
export const DELETE_NOTE = 'DELETE_NOTE'
export const DELETE_MULTIPLE_NOTES = 'DELETE_MULTIPLE_NOTES'
export const SET_SELECTED_NOTES = 'SET_SELECTED_NOTES'
export const SET_TOTAL_NOTES = 'SET_TOTAL_NOTES'
export const RESET_SELECTED_NOTE = 'RESET_SELECTED_NOTE'
export const SET_SELECT_ALL_STATE = 'SET_SELECT_ALL_STATE'
