var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "calculator-main-container" }, [
    _c(
      "div",
      { staticClass: "container mt-5 pt-5" },
      [
        _c("vue-advanced-calculator", {
          attrs: {
            description: "Advance Cacluclator",
            title: "OmtBiz Cacluclator",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }