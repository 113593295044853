var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-content item-create" }, [
    _c("div", { staticClass: "page-header" }, [
      _c("h3", { staticClass: "page-title" }, [
        _vm._v(
          _vm._s(
            _vm.isEdit ? _vm.$t("banks.edit_bank") : _vm.$t("banks.new_bank")
          )
        ),
      ]),
      _vm._v(" "),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              {
                attrs: { slot: "item-title", to: "/invoices" },
                slot: "item-title",
              },
              [_vm._v(_vm._s(_vm.$t("general.home")))]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              {
                attrs: { slot: "item-title", to: "/banks" },
                slot: "item-title",
              },
              [_vm._v(_vm._s(_vm.$tc("banks.banks", 2)))]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item" }, [
          _c("a", { attrs: { href: "#" } }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.isEdit
                    ? _vm.$t("banks.edit_bank")
                    : _vm.$t("banks.new_bank")
                )
            ),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-8" }, [
        _c("div", { staticClass: "card" }, [
          _c(
            "form",
            {
              attrs: { action: "" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submitBank.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "card-body" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v(_vm._s(_vm.$t("banks.name"))),
                    ]),
                    _c("span", { staticClass: "text-danger" }, [_vm._v(" *")]),
                    _vm._v(" "),
                    _c("base-input", {
                      attrs: {
                        invalid: _vm.$v.formData.name.$error,
                        focus: "",
                        type: "text",
                        name: "name",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$v.formData.name.$touch()
                        },
                      },
                      model: {
                        value: _vm.formData.name,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData,
                            "name",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formData.name",
                      },
                    }),
                    _vm._v(" "),
                    _vm.$v.formData.name.$error
                      ? _c("div", [
                          !_vm.$v.formData.name.required
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("validation.required")) + " "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.$v.formData.name.minLength
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$tc(
                                        "validation.name_min_length",
                                        _vm.$v.formData.name.$params.minLength
                                          .min,
                                        {
                                          count:
                                            _vm.$v.formData.name.$params
                                              .minLength.min,
                                        }
                                      )
                                    ) +
                                    "\n                "
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v(_vm._s(_vm.$t("banks.design_no"))),
                    ]),
                    _vm._v(" "),
                    _c("base-input", {
                      attrs: { focus: "", type: "text", name: "design_no" },
                      model: {
                        value: _vm.formData.design_no,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData,
                            "design_no",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formData.design_no",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v(_vm._s(_vm.$t("banks.rate"))),
                    ]),
                    _vm._v(" "),
                    _c("base-input", {
                      attrs: { focus: "", type: "text", name: "rate" },
                      model: {
                        value: _vm.formData.rate,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData,
                            "rate",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formData.rate",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v(_vm._s(_vm.$t("banks.average"))),
                    ]),
                    _vm._v(" "),
                    _c("base-input", {
                      attrs: { focus: "", type: "text", name: "average" },
                      model: {
                        value: _vm.formData.average,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData,
                            "average",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formData.average",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v(_vm._s(_vm.$t("banks.per_price"))),
                    ]),
                    _vm._v(" "),
                    _c("base-input", {
                      attrs: { focus: "", type: "text", name: "per_price" },
                      model: {
                        value: _vm.formData.per_price,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData,
                            "per_price",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formData.per_price",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v(_vm._s(_vm.$t("banks.bank"))),
                    ]),
                    _vm._v(" "),
                    _c("base-text-area", {
                      attrs: { focus: "", type: "text", name: "bank" },
                      model: {
                        value: _vm.formData.bank,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData,
                            "bank",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formData.bank",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(
                      "base-button",
                      {
                        staticClass: "collapse-button",
                        attrs: {
                          loading: _vm.isLoading,
                          disabled: _vm.isLoading,
                          icon: "save",
                          color: "theme",
                          type: "submit",
                        },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.isEdit
                                ? _vm.$t("banks.update_bank")
                                : _vm.$t("banks.save_bank")
                            ) +
                            "\n              "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }