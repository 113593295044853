export default {
  invoice_id: null,
  item_id: null,
  id: null,
  name: '',
  title: '',
  description: null,
  quantity: null,
  price: 0,
  sale_price: 0,
  total: 0,
}
