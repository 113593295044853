var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "estimate-create-page main-content" },
    [
      _c("div", { staticClass: "page-header" }, [
        _c(
          "div",
          { staticClass: "page-actions row" },
          [
            _c(
              "router-link",
              {
                staticClass: "col-xs-2",
                attrs: { slot: "item-title", to: "/estimates" },
                slot: "item-title",
              },
              [
                _c(
                  "base-button",
                  {
                    attrs: { size: "large", icon: "envelope", color: "theme" },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("estimates.title")) +
                        "\n        "
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      !_vm.initLoading
        ? _c(
            "form",
            {
              attrs: { action: "" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submitEstimateData.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "page-header" }, [
                _vm.$route.name === "estimates.edit"
                  ? _c("h3", { staticClass: "page-title" }, [
                      _vm._v(_vm._s(_vm.$t("estimates.edit_estimate"))),
                    ])
                  : _c("h3", { staticClass: "page-title" }, [
                      _vm._v(_vm._s(_vm.$t("estimates.new_estimate")) + " "),
                    ]),
                _vm._v(" "),
                _c("ol", { staticClass: "breadcrumb" }, [
                  _c(
                    "li",
                    { staticClass: "breadcrumb-item" },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: { slot: "item-title", to: "/estimates" },
                          slot: "item-title",
                        },
                        [_vm._v(_vm._s(_vm.$tc("estimates.estimate", 2)))]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.$route.name === "estimates.edit"
                    ? _c("li", { staticClass: "breadcrumb-item" }, [
                        _vm._v(_vm._s(_vm.$t("estimates.edit_estimate"))),
                      ])
                    : _c("li", { staticClass: "breadcrumb-item" }, [
                        _vm._v(_vm._s(_vm.$t("estimates.new_estimate"))),
                      ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row estimate-input-group" }, [
                _c(
                  "div",
                  { staticClass: "col-md-5 estimate-customer-container" },
                  [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v(_vm._s(_vm.$t("receipts.list"))),
                    ]),
                    _c("span", { staticClass: "text-danger" }, [_vm._v(" *")]),
                    _vm._v(" "),
                    _c("base-select", {
                      attrs: {
                        invalid: _vm.$v.newEstimate.debtors.$error,
                        options: _vm.sundryDebtorsList,
                        required: "required",
                        searchable: true,
                        "show-labels": false,
                        "allow-empty": false,
                        disabled: _vm.$route.name === "estimates.edit",
                        placeholder: _vm.$t("receipts.select_a_list"),
                        label: "name",
                        "track-by": "id",
                      },
                      model: {
                        value: _vm.setEstimateDebtor,
                        callback: function ($$v) {
                          _vm.setEstimateDebtor = $$v
                        },
                        expression: "setEstimateDebtor",
                      },
                    }),
                    _vm._v(" "),
                    _vm.$v.newEstimate.debtors.$error
                      ? _c("div", [
                          !_vm.$v.newEstimate.debtors.required
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(_vm._s(_vm.$tc("validation.required"))),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col estimate-input" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col collapse-input" }, [
                      _c("label", [
                        _vm._v(
                          _vm._s(_vm.$tc("estimates.estimate", 1)) +
                            " " +
                            _vm._s(_vm.$t("estimates.date"))
                        ),
                        _c("span", { staticClass: "text-danger" }, [
                          _vm._v(" * "),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.newEstimate.estimate_date,
                            expression: "newEstimate.estimate_date",
                          },
                        ],
                        staticClass: "base-prefix-input",
                        attrs: {
                          type: "date",
                          "data-date": "",
                          "data-date-format": "DD/MM/YYYY",
                          disabled: _vm.isEdit,
                        },
                        domProps: { value: _vm.newEstimate.estimate_date },
                        on: {
                          change: function ($event) {
                            return _vm.$v.newEstimate.estimate_date.$touch()
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.newEstimate,
                              "estimate_date",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.$v.newEstimate.estimate_date.$error &&
                      !_vm.$v.newEstimate.estimate_date.required
                        ? _c("span", { staticClass: "text-danger" }, [
                            _vm._v(
                              " " + _vm._s(_vm.$t("validation.required")) + " "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col collapse-input" },
                      [
                        _c("label", [
                          _vm._v(_vm._s(_vm.$t("estimates.estimate_number"))),
                          _c("span", { staticClass: "text-danger" }, [
                            _vm._v(" * "),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("base-prefix-input", {
                          attrs: {
                            invalid: _vm.$v.estimateNumAttribute.$error,
                            prefix: _vm.estimatePrefix,
                            icon: "hashtag",
                            "prefix-width": 55,
                            disabled: true,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.$v.estimateNumAttribute.$touch()
                            },
                          },
                          model: {
                            value: _vm.estimateNumAttribute,
                            callback: function ($$v) {
                              _vm.estimateNumAttribute = $$v
                            },
                            expression: "estimateNumAttribute",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.$v.estimateNumAttribute.$error &&
                                  !_vm.$v.estimateNumAttribute.required,
                                expression:
                                  "$v.estimateNumAttribute.$error && !$v.estimateNumAttribute.required",
                              },
                            ],
                            staticClass: "text-danger mt-1",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$tc("validation.required")) +
                                "  "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "table-responsive" }, [
                _c(
                  "table",
                  { staticClass: "table item-table" },
                  [
                    _c("colgroup", [
                      _c("col", { staticStyle: { width: "40%" } }),
                      _vm._v(" "),
                      _c("col", { staticStyle: { width: "10%" } }),
                      _vm._v(" "),
                      _c("col", { staticStyle: { width: "15%" } }),
                      _vm._v(" "),
                      _c("col", { staticStyle: { width: "15%" } }),
                      _vm._v(" "),
                      _vm.discountPerInventory === "YES"
                        ? _c("col", { staticStyle: { width: "15%" } })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("col", { staticStyle: { width: "15%" } }),
                    ]),
                    _vm._v(" "),
                    _c("thead", { staticClass: "item-table-header" }, [
                      _c("tr", [
                        _c("th", { staticClass: "text-left" }, [
                          _c(
                            "span",
                            {
                              staticClass:
                                "column-heading heading-1 item-heading",
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.$tc("estimates.inventory.title", 2)
                                  ) +
                                  "\n              "
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("th", { staticClass: "text-right" }, [
                          _c("span", { staticClass: "column-heading" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$t("estimates.inventory.quantity")) +
                                "\n              "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("th", { staticClass: "text-left" }, [
                          _c("span", { staticClass: "column-heading" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$t("estimates.inventory.price")) +
                                "\n              "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("th", { staticClass: "text-left" }, [
                          _c("span", { staticClass: "column-heading" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.$t("estimates.inventory.sale_price")
                                ) +
                                "\n              "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _vm.discountPerInventory === "YES"
                          ? _c("th", { staticClass: "text-right" }, [
                              _c("span", { staticClass: "column-heading" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$t("estimates.inventory.discount")
                                    ) +
                                    "\n              "
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("th", { staticClass: "text-right" }, [
                          _c(
                            "span",
                            { staticClass: "column-heading amount-heading" },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("estimates.inventory.amount")) +
                                  "\n              "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "draggable",
                      {
                        staticClass: "item-body",
                        attrs: { tag: "tbody", handle: ".handle" },
                        model: {
                          value: _vm.inventoryBind,
                          callback: function ($$v) {
                            _vm.inventoryBind = $$v
                          },
                          expression: "inventoryBind",
                        },
                      },
                      _vm._l(_vm.inventoryBind, function (each, index) {
                        return _c("invoice-inventory", {
                          key: each.name + index,
                          ref: "estimateInventory",
                          refInFor: true,
                          attrs: {
                            index: index,
                            "inventory-data": each,
                            currency: _vm.currency,
                            "discount-per-inventory": _vm.discountPerInventory,
                            "inventory-type": "estimate",
                            "inventory-list": _vm.inventoryListBind,
                            "inventory-negative": _vm.inventoryNegative,
                          },
                          on: {
                            remove: _vm.removeInventory,
                            update: _vm.updateInventoryBounce,
                            inventoryValidate: _vm.checkInventoryData,
                            endlist: _vm.showEndList,
                          },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _vm.showAddNewInventory
                ? _c(
                    "button",
                    {
                      staticClass: "add-item-action add-estimate-item",
                      on: { click: _vm.addInventory },
                    },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "mr-2",
                        attrs: { icon: "shopping-basket" },
                      }),
                      _vm._v(
                        "\n      " +
                          _vm._s(_vm.$t("estimates.add_item")) +
                          "\n    "
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.showEndOfList
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      staticStyle: { margin: "10px" },
                      on: { click: _vm.removeEndOfList },
                    },
                    [_vm._v("\n      End Of List\n    ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "estimate-foot" }, [
                _c(
                  "div",
                  [
                    _c("label", [_vm._v(_vm._s(_vm.$t("estimates.notes")))]),
                    _vm._v(" "),
                    _c("base-text-area", {
                      attrs: { rows: "3", cols: "50" },
                      on: {
                        input: function ($event) {
                          return _vm.$v.newEstimate.notes.$touch()
                        },
                      },
                      model: {
                        value: _vm.newEstimate.notes,
                        callback: function ($$v) {
                          _vm.$set(_vm.newEstimate, "notes", $$v)
                        },
                        expression: "newEstimate.notes",
                      },
                    }),
                    _vm._v(" "),
                    _vm.$v.newEstimate.notes.$error
                      ? _c("div", [
                          !_vm.$v.newEstimate.notes.maxLength
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("validation.notes_maxlength"))
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "estimate-total" }, [
                  _c("div", { staticClass: "section" }, [
                    _c("label", { staticClass: "estimate-label" }, [
                      _vm._v(_vm._s(_vm.$t("estimates.quantity"))),
                    ]),
                    _vm._v(" "),
                    _c("label", {}, [
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.totalQuantity(_vm.inventoryBind)
                          ),
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "section" }, [
                    _c("label", { staticClass: "estimate-label" }, [
                      _vm._v(_vm._s(_vm.$t("estimates.sub_total"))),
                    ]),
                    _vm._v(" "),
                    _c("label", { staticClass: "estimate-amount" }, [
                      _vm._v(
                        "\n            ₹ " +
                          _vm._s(_vm.subtotal) +
                          "\n          "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "section border-top mt-3" }, [
                    _c("label", { staticClass: "estimate-label" }, [
                      _vm._v(
                        _vm._s(_vm.$t("estimates.total")) +
                          " " +
                          _vm._s(_vm.$t("estimates.amount")) +
                          ":"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("label", { staticClass: "estimate-amount total" }, [
                      _vm._v(
                        "\n            ₹ " + _vm._s(_vm.total) + "\n          "
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "page-actions row" },
                [
                  _c(
                    "base-button",
                    {
                      staticClass: "estimate-action-btn",
                      attrs: {
                        loading: _vm.isLoading,
                        disabled: _vm.isLoading,
                        icon: "save",
                        color: "theme",
                        type: "submit",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("estimates.save_estimate")) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          )
        : _c("base-loader"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }