var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "vue-editable-grid" }, [
    _c("div", { staticClass: "grid-tools" }, [
      _c(
        "div",
        { staticClass: "grid-tools-left" },
        [
          _vm._t("header"),
          _vm._v(" "),
          _vm.pageCount
            ? _c("paginate", {
                staticClass: "grid-tool",
                attrs: {
                  page: _vm.page,
                  pages: _vm.pages,
                  "page-count": _vm.pageCount,
                  "total-rows": _vm.rowDataFiltered.length,
                },
                on: {
                  "count-changed": function ($event) {
                    _vm.pageCount = $event
                  },
                  prev: function ($event) {
                    _vm.page--
                  },
                  next: function ($event) {
                    _vm.page++
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("filters", {
            staticClass: "grid-tool",
            attrs: { filters: _vm.filter, columnDefs: _vm.columnDefs },
            on: { remove: _vm.removeFilter },
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "grid-tools-right" }, [_vm._t("header-r")], 2),
    ]),
    _vm._v(" "),
    _c("div", { ref: "container", staticClass: "grid-table-container" }, [
      _c(
        "table",
        {
          ref: "table",
          staticClass: "grid-table",
          class: { filters: _vm.enableFilters },
        },
        [
          _c("thead", { ref: "head" }, [
            _c(
              "tr",
              {
                staticClass: "headers-row",
                style: { "grid-template-columns": _vm.gridTemplateColumns },
              },
              _vm._l(_vm.columnDefs, function (column, index) {
                return _c(
                  "th",
                  {
                    key: index,
                    class: {
                      sortable: column.sortable,
                      sorting: column.field === _vm.sortByColumn,
                      descending: _vm.sortByDesc,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.sort(column)
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "header-content" }, [
                      _vm._v(_vm._s(column.headerName)),
                    ]),
                    _c("span", {
                      staticClass: "resize-handle",
                      on: {
                        mousedown: function ($event) {
                          return _vm.initResize(column, $event)
                        },
                        click: function ($event) {
                          $event.stopPropagation()
                        },
                      },
                    }),
                  ]
                )
              }),
              0
            ),
            _vm._v(" "),
            _vm.enableFilters
              ? _c(
                  "tr",
                  {
                    staticClass: "filters-row",
                    style: { "grid-template-columns": _vm.gridTemplateColumns },
                  },
                  _vm._l(_vm.columnDefs, function (column, index) {
                    return _c(
                      "th",
                      { key: index, class: { filter: column.filter } },
                      [
                        column.filter
                          ? _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.filter[column.field],
                                  expression: "filter[column.field]",
                                },
                              ],
                              attrs: {
                                type: "text",
                                placeholder: "Search with , or &",
                              },
                              domProps: { value: _vm.filter[column.field] },
                              on: {
                                input: [
                                  function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.filter,
                                      column.field,
                                      $event.target.value
                                    )
                                  },
                                  _vm.filtersChanged,
                                ],
                              },
                            })
                          : _vm._e(),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("tbody", { ref: "body" }, [
            _c(
              "div",
              {
                style: {
                  "min-height": `${_vm.rowDataPage.length * _vm.itemHeight}px`,
                },
              },
              _vm._l(_vm.visibleRows, function (row, rowIndex) {
                return _c(
                  "tr",
                  {
                    key: row[_vm.rowDataKey],
                    staticClass: "gridrow",
                    style: {
                      "grid-template-columns": _vm.gridTemplateColumns,
                      transform: `translateY(${
                        _vm.itemHeight * rowIndex +
                        _vm.itemHeight * _vm.offsetRows
                      }px)`,
                      height: `${_vm.itemHeight}px`,
                    },
                  },
                  _vm._l(_vm.columnDefs, function (column, columnIndex) {
                    return _c("cell", {
                      key:
                        _vm.$route.name === "vouchers.edit"
                          ? columnIndex + row.account_id
                          : "each-" + columnIndex + rowIndex,
                      ref: `cell`,
                      refInFor: true,
                      attrs: {
                        column: column,
                        row: row,
                        columnIndex: columnIndex,
                        rowIndex: _vm.offsetRows + rowIndex,
                        selStart: _vm.selStart,
                        selEnd: _vm.selEnd,
                        cellEditing: _vm.cellEditing,
                        cellsWithErrors: _vm.cellsWithErrors,
                        onlyBorder: _vm.onlyBorder,
                        masterOptions: _vm.masterOptions,
                        placeholder: column.placeholder,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.selectCell(
                            _vm.offsetRows + rowIndex,
                            columnIndex,
                            $event
                          )
                        },
                        dblclick: function ($event) {
                          return _vm.tryEdit(
                            row,
                            column,
                            _vm.offsetRows + rowIndex,
                            columnIndex
                          )
                        },
                        edited: _vm.cellEdited,
                        "link-clicked": function ($event) {
                          return _vm.linkClicked(
                            row,
                            column,
                            _vm.offsetRows + rowIndex,
                            columnIndex
                          )
                        },
                        contextmenu: function ($event) {
                          return _vm.contextMenu(
                            row,
                            column,
                            rowIndex,
                            columnIndex,
                            $event
                          )
                        },
                        mousedown: function ($event) {
                          return _vm.startSelection(
                            _vm.offsetRows + rowIndex,
                            columnIndex,
                            $event
                          )
                        },
                        mouseover: function ($event) {
                          return _vm.onSelection(
                            _vm.offsetRows + rowIndex,
                            columnIndex
                          )
                        },
                        mouseup: _vm.stopSelection,
                      },
                    })
                  }),
                  1
                )
              }),
              0
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("textarea", { ref: "tmp", staticClass: "hidde" }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }