var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "sidebar-left" }, [
    _c("div", { staticClass: "sidebar-body scroll-pane" }, [
      _c(
        "div",
        { staticClass: "side-nav" },
        _vm._l(_vm.menu, function (menuItems, index) {
          return _c(
            "div",
            { key: index, staticClass: "menu-group" },
            _vm._l(
              menuItems.filter((i) => i.meta.includes(_vm.role)),
              function (item, index1) {
                return _c(
                  "router-link",
                  {
                    key: index1,
                    staticClass: "menu-item",
                    attrs: { to: item.route },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.Toggle.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "icon menu-icon",
                      attrs: { icon: item.icon },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "ml-3 menu-text" }, [
                      _vm._v(_vm._s(_vm.$t(item.title))),
                    ]),
                  ],
                  1
                )
              }
            ),
            1
          )
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn ml-5 mb-3 btn-default",
          attrs: { type: "button" },
          on: { click: _vm.showModal },
        },
        [
          _c(
            "svg",
            {
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                viewBox: "0 0 50 50",
                width: "30px",
                height: "30px",
              },
            },
            [
              _c("path", {
                attrs: {
                  d: "M 14 4 C 8.4886661 4 4 8.4886661 4 14 L 4 36 C 4 41.511334 8.4886661 46 14 46 L 36 46 C 41.511334 46 46 41.511334 46 36 L 46 14 C 46 8.4886661 41.511334 4 36 4 L 14 4 z M 14 6 L 36 6 C 40.430666 6 44 9.5693339 44 14 L 44 36 C 44 40.430666 40.430666 44 36 44 L 14 44 C 9.5693339 44 6 40.430666 6 36 L 6 14 C 6 9.5693339 9.5693339 6 14 6 z M 18 9 C 15.250484 9 13 11.250484 13 14 L 13 36 C 13 38.749516 15.250484 41 18 41 L 32 41 C 34.749516 41 37 38.749516 37 36 L 37 14 C 37 11.250484 34.749516 9 32 9 L 18 9 z M 18 11 L 32 11 C 33.668484 11 35 12.331516 35 14 L 35 36 C 35 37.668484 33.668484 39 32 39 L 18 39 C 16.331516 39 15 37.668484 15 36 L 15 14 C 15 12.331516 16.331516 11 18 11 z M 18 13 C 17.448 13 17 13.448 17 14 L 17 18 C 17 18.552 17.448 19 18 19 L 32 19 C 32.552 19 33 18.552 33 18 L 33 14 C 33 13.448 32.552 13 32 13 L 18 13 z M 19 21 A 2 2 0 0 0 19 25 A 2 2 0 0 0 19 21 z M 25 21 A 2 2 0 0 0 25 25 A 2 2 0 0 0 25 21 z M 31 21 A 2 2 0 0 0 31 25 A 2 2 0 0 0 31 21 z M 19 27 A 2 2 0 0 0 19 31 A 2 2 0 0 0 19 27 z M 25 27 A 2 2 0 0 0 25 31 A 2 2 0 0 0 25 27 z M 31 27 A 2 2 0 0 0 31 31 A 2 2 0 0 0 31 27 z M 19 33 C 17.895 33 17 33.895 17 35 C 17 36.105 17.895 37 19 37 L 25 37 C 26.105 37 27 36.105 27 35 C 27 33.895 26.105 33 25 33 L 19 33 z M 31 33 A 2 2 0 0 0 31 37 A 2 2 0 0 0 31 33 z",
                },
              }),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "modal", attrs: { id: "showModal" } }, [
        _c("div", { staticClass: "modal-dialog mt-5 pt-5" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c("h5", { staticClass: "modal-title" }, [_vm._v(" Calculator")]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: {
                    type: "button",
                    "data-dismiss": "modal",
                    "aria-label": "Close",
                  },
                  on: { click: _vm.closeModal },
                },
                [
                  _c("span", { attrs: { "aria-hidden": "true" } }, [
                    _vm._v("×"),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "modal-body" },
              [
                _c("vue-advanced-calculator", {
                  attrs: {
                    description: "Advance Cacluclator",
                    title: "Cacluclator",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }