var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-content item-create" }, [
    _c("div", { staticClass: "page-header" }, [
      _c("h3", { staticClass: "page-title" }, [
        _vm._v(
          _vm._s(
            _vm.isEdit
              ? _vm.$t("ledgers.edit_ledger")
              : _vm.$t("ledgers.new_ledger")
          )
        ),
      ]),
      _vm._v(" "),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              {
                attrs: { slot: "item-title", to: "/invoices" },
                slot: "item-title",
              },
              [_vm._v(_vm._s(_vm.$t("general.home")))]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              {
                attrs: { slot: "item-title", to: "/ledgers" },
                slot: "item-title",
              },
              [_vm._v(_vm._s(_vm.$tc("ledgers.ledgers_list", 2)))]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item" }, [
          _c("a", { attrs: { href: "#" } }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.isEdit
                    ? _vm.$t("ledgers.edit_ledger")
                    : _vm.$t("ledgers.new_ledger")
                )
            ),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12" }, [
        _c("div", { staticClass: "card" }, [
          _c(
            "div",
            { staticClass: "card-body" },
            [
              _c("vue-editable-grid", {
                ref: "grid",
                staticClass: "my-grid-class",
                attrs: {
                  id: "mygrid",
                  "column-defs": _vm.columnDefs,
                  "row-data": _vm.rows,
                  "row-data-key": "ledgerId",
                  "master-options": _vm.masterData,
                },
                on: {
                  "cell-updated": _vm.cellUpdated,
                  "row-selected": _vm.rowSelected,
                },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function () {
                      return [
                        _vm._v(
                          "\n                Add / Edit Account Vouchers\n              "
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "header-r",
                    fn: function () {
                      return [
                        _vm._v(
                          "\n                Total rows: " +
                            _vm._s(_vm.rows.length) +
                            "\n              "
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-theme-outline",
                  on: {
                    click: function ($event) {
                      return _vm.addNewRow()
                    },
                  },
                },
                [_vm._v("Add new")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  on: {
                    click: function ($event) {
                      return _vm.submitVoucher()
                    },
                  },
                },
                [_vm._v("Save Voucher")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }