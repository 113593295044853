var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "td",
    {
      staticClass: "cell noselect",
      class: {
        selected: !_vm.onlyBorder && _vm.selected,
        editable: _vm.editable,
        invalid: _vm.invalid,
        [_vm.column.type || "text"]: true,
      },
      style: _vm.cellStyle,
      attrs: {
        id: `cell${_vm.rowIndex}-${_vm.columnIndex}`,
        title: _vm.invalid,
      },
      on: {
        click: function ($event) {
          return _vm.$emit("click", $event)
        },
      },
    },
    [
      _vm.inputType === "select"
        ? _c("span", { staticClass: "editable-field" }, [
            _vm.column.field === "type"
              ? _c(
                  "span",
                  [
                    _c("base-select", {
                      ref: "select",
                      class: {
                        "remove-extra": !_vm.selectTypeBind,
                        "width-100 style-chooser": true,
                      },
                      attrs: {
                        options: _vm.setOptions,
                        searchable: true,
                        "show-labels": false,
                        "allow-empty": false,
                        placeholder: "",
                        "track-by": "id",
                        label: "name",
                        "open-direction": "bottom",
                      },
                      model: {
                        value: _vm.selectTypeBind,
                        callback: function ($$v) {
                          _vm.selectTypeBind = $$v
                        },
                        expression: "selectTypeBind",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.column.field === "account"
              ? _c(
                  "span",
                  [
                    _c("base-select", {
                      ref: "select",
                      class: {
                        "remove-extra": !_vm.selectAccountBind,
                        "width-500 style-chooser": true,
                      },
                      attrs: {
                        options: _vm.setOptions,
                        searchable: true,
                        "show-labels": false,
                        "allow-empty": false,
                        placeholder: "",
                        "track-by": "id",
                        label: "name",
                        "open-direction": "bottom",
                      },
                      model: {
                        value: _vm.selectAccountBind,
                        callback: function ($$v) {
                          _vm.selectAccountBind = $$v
                        },
                        expression: "selectAccountBind",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : _c("span", [
            _c("span", { staticClass: "editable-field" }, [
              _c("input", {
                key: _vm.value,
                ref: "input",
                attrs: {
                  type: _vm.inputType,
                  min: 0,
                  placeholder: _vm.placeholder,
                  disabled: _vm.disableInput,
                },
                domProps: { value: _vm.value },
                on: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.setEditableValue.apply(null, arguments)
                  },
                  focus: function ($event) {
                    _vm.editPending = true
                  },
                  blur: _vm.leaved,
                },
              }),
            ]),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }