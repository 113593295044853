var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-content item-create" }, [
    _c("div", { staticClass: "page-header" }, [
      _c("h3", { staticClass: "page-title" }, [
        _vm._v(
          _vm._s(
            _vm.isEdit
              ? _vm.$t("vouchers.edit_voucher")
              : _vm.$t("vouchers.new_voucher")
          )
        ),
      ]),
      _vm._v(" "),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              {
                attrs: { slot: "item-title", to: "/invoices" },
                slot: "item-title",
              },
              [_vm._v(_vm._s(_vm.$t("general.home")))]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              {
                attrs: { slot: "item-title", to: "/vouchers" },
                slot: "item-title",
              },
              [_vm._v(_vm._s(_vm.$tc("vouchers.vouchers_list", 2)))]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item" }, [
          _c("a", { attrs: { href: "#" } }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.isEdit
                    ? _vm.$t("vouchers.edit_voucher")
                    : _vm.$t("vouchers.new_voucher")
                )
            ),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("div", { staticClass: "card" }, [
          _c(
            "div",
            { staticClass: "card-body" },
            [
              _c("vue-editable-grid", {
                ref: "grid",
                staticClass: "my-grid-class",
                attrs: {
                  id: "mygrid",
                  "column-defs": _vm.columnDefs,
                  "row-data": _vm.rows,
                  "row-data-key": "voucherId",
                  "master-options": _vm.masterDataBind,
                },
                on: {
                  "cell-updated": _vm.cellUpdated,
                  "row-selected": _vm.rowSelected,
                },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function () {
                      return [
                        _vm._v(
                          "\n                Add / Edit Account Vouchers\n              "
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "header-r",
                    fn: function () {
                      return [
                        _vm._v(
                          "\n                Total rows: " +
                            _vm._s(_vm.rows.length) +
                            "\n              "
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-12 p-0 mb-4 mt-4" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("label", [
                      _vm._v(_vm._s(_vm.$t("vouchers.description"))),
                    ]),
                    _vm._v(" "),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.short_narration,
                          expression: "short_narration",
                        },
                      ],
                      staticClass: "form-control description-input mb-3",
                      attrs: {
                        type: "text",
                        autofocus: "",
                        rows: "2",
                        width: "400",
                        id: "narration-voucher",
                        placeholder: "Type Short Narration (optional)",
                      },
                      domProps: { value: _vm.short_narration },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.short_narration = $event.target.value
                        },
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-md-6" },
                    [
                      _c("label", [_vm._v(_vm._s(_vm.$t("vouchers.date")))]),
                      _c("span", { staticClass: "text-danger" }, [
                        _vm._v(" * "),
                      ]),
                      _vm._v(" "),
                      _c("base-date-picker", {
                        attrs: {
                          "calendar-button": true,
                          "calendar-button-icon": "calendar",
                        },
                        model: {
                          value: _vm.date,
                          callback: function ($$v) {
                            _vm.date = $$v
                          },
                          expression: "date",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-theme-outline",
                  on: {
                    click: function ($event) {
                      return _vm.addNewRow()
                    },
                  },
                },
                [_vm._v("Add new")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  on: {
                    click: function ($event) {
                      return _vm.validateSubmitVoucher()
                    },
                  },
                },
                [_vm._v("Save Voucher")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }