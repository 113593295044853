var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "item-selector" },
    [
      _vm.groupOptions.id
        ? _c("div", { staticClass: "selected-item" }, [
            _vm._v("\n    " + _vm._s(_vm.groupSelect.name) + "\n    "),
            _c(
              "span",
              {
                staticClass: "deselect-icon",
                on: { click: _vm.deselectGroup },
              },
              [_c("font-awesome-icon", { attrs: { icon: "times-circle" } })],
              1
            ),
          ])
        : _c(
            "base-select",
            {
              ref: "baseSelect",
              staticClass: "multi-select-item",
              attrs: {
                options: _vm.groupOptions,
                "show-labels": false,
                "preserve-search": true,
                "initial-search": _vm.groupOptions.name,
                invalid: _vm.invalid,
                placeholder: _vm.$t("groups.select_an_group"),
                label: "name",
              },
              on: {
                value: _vm.onTextChange,
                select: (val) => _vm.$emit("select", val),
              },
              model: {
                value: _vm.groupSelect,
                callback: function ($$v) {
                  _vm.groupSelect = $$v
                },
                expression: "groupSelect",
              },
            },
            [
              _c("div", { attrs: { slot: "afterList" }, slot: "afterList" }, [
                _c(
                  "button",
                  {
                    staticClass: "list-add-button",
                    attrs: { type: "button" },
                    on: { click: _vm.openGroupModal },
                  },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "icon",
                      attrs: { icon: "cart-plus" },
                    }),
                    _vm._v(" "),
                    _c("label", [
                      _vm._v(_vm._s(_vm.$t("groups.add_new_group"))),
                    ]),
                  ],
                  1
                ),
              ]),
            ]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }