var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "grid-filters" },
    [
      _vm.filtersLength
        ? _c(
            "button",
            {
              staticClass: "filter remove",
              on: {
                click: function ($event) {
                  return _vm.remove()
                },
              },
            },
            [_vm._v("x")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.fieldsWithData, function (field) {
        return _c(
          "button",
          {
            key: field,
            staticClass: "filter",
            on: {
              click: function ($event) {
                return _vm.remove(field)
              },
            },
          },
          [
            _vm._v(
              _vm._s(_vm.colNames[field]) + ": " + _vm._s(_vm.filters[field])
            ),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }