export const SET_ORDERS_PENDING = 'SET_ORDERS_PENDING'
export const SET_ORDERS_COMPLETED = 'SET_ORDERS_COMPLETED'
export const ADD_ORDER_PENDING = 'ADD_ORDER_PENDING'
export const ADD_ORDER_COMPLETED = 'ADD_ORDER_COMPLETED'
export const UPDATE_ORDER_PENDING = 'UPDATE_ORDER_PENDING'
export const UPDATE_ORDER_COMPLETED = 'UPDATE_ORDER_COMPLETED'
export const DELETE_ORDER_PENDING = 'DELETE_ORDER_PENDING'
export const DELETE_ORDER_COMPLETED = 'DELETE_ORDER_COMPLETED'
export const DELETE_MULTIPLE_ORDERS_PENDING = 'DELETE_MULTIPLE_ORDERS_PENDING'
export const DELETE_MULTIPLE_ORDERS_COMPLETED = 'DELETE_MULTIPLE_ORDERS_COMPLETED'
export const SET_SELECTED_ORDERS = 'SET_SELECTED_ORDERS'
export const SET_TOTAL_ORDERS_PENDING = 'SET_TOTAL_ORDERS_PENDING'
export const SET_TOTAL_ORDERS_COMPLETED = 'SET_TOTAL_ORDERS_COMPLETED'
export const RESET_CUSTOMER = 'RESET_CUSTOMER'
export const RESET_ITEM = 'RESET_ITEM'
export const SET_CUSTOMER = 'SET_CUSTOMER'
export const SET_ITEM = 'SET_ITEM'
export const SET_TEMPLATE_ID = 'SET_TEMPLATE_ID'
export const SELECT_CUSTOMER = 'SELECT_CUSTOMER'
export const RESET_SELECTED_CUSTOMER = 'RESET_SELECTED_CUSTOMER'
export const SET_SELECT_ALL_STATE = 'SET_SELECT_ALL_STATE'
export const RESET_SELECTED_ORDERS = 'RESET_SELECTED_ORDERS'
export const UPDATE_ORDER_STATUS_PENDING = 'UPDATE_ORDER_STATUS_PENDING'
export const UPDATE_ORDER_STATUS_COMPLETED = 'UPDATE_ORDER_STATUS_COMPLETED'
