var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "item-selector" },
    [
      _c(
        "base-select",
        {
          ref: "baseSelect",
          attrs: {
            options: _vm.inventoriesOptions,
            "show-labels": true,
            "preserve-search": false,
            "allow-empty": false,
            searchable: true,
            "initial-search": _vm.inventory.name,
            "custom-label": _vm.customLabel,
            invalid: _vm.invalid,
            placeholder: _vm.$t("invoices.inventory.select_an_inventory"),
            "do-not-select-default": true,
            disabled: _vm.isDisable,
            label: "name",
            "track-by": "id",
          },
          on: { value: _vm.onTextChange },
          model: {
            value: _vm.inventorySelected,
            callback: function ($$v) {
              _vm.inventorySelected = $$v
            },
            expression: "inventorySelected",
          },
        },
        [
          _c("div", { attrs: { slot: "afterList" }, slot: "afterList" }, [
            _c(
              "button",
              {
                staticClass: "list-add-button",
                attrs: { type: "button" },
                on: { click: _vm.openInventoryModal },
              },
              [
                _c("font-awesome-icon", {
                  staticClass: "icon",
                  attrs: { icon: "cart-plus" },
                }),
                _vm._v(" "),
                _c("label", [_vm._v(_vm._s(_vm.$t("general.add_new_item")))]),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }