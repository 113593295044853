export const SET_ESTIMATES_DRAFT = 'SET_ESTIMATES_DRAFT'
export const SET_ESTIMATES_SENT = 'SET_ESTIMATES_SENT'
export const ADD_ESTIMATE_DRAFT = 'ADD_ESTIMATE_DRAFT'
export const ADD_ESTIMATE_SENT = 'ADD_ESTIMATE_SENT'
export const UPDATE_ESTIMATE_DRAFT = 'UPDATE_ESTIMATE_DRAFT'
export const UPDATE_ESTIMATE_SENT = 'UPDATE_ESTIMATE_SENT'
export const DELETE_ESTIMATE_DRAFT = 'DELETE_ESTIMATE_DRAFT'
export const DELETE_ESTIMATE_SENT = 'DELETE_ESTIMATE_SENT'
export const DELETE_MULTIPLE_ESTIMATES = 'DELETE_MULTIPLE_ESTIMATES'
export const SET_SELECTED_ESTIMATES = 'SET_SELECTED_ESTIMATES'
export const SET_TOTAL_ESTIMATES_DRAFT = 'SET_TOTAL_ESTIMATES_DRAFT'
export const SET_TOTAL_ESTIMATES_SENT = 'SET_TOTAL_ESTIMATES_SENT'
export const RESET_CUSTOMER = 'RESET_CUSTOMER'
export const RESET_ITEM = 'RESET_ITEM'
export const SET_CUSTOMER = 'SET_CUSTOMER'
export const SET_ITEM = 'SET_ITEM'
export const SET_TEMPLATE_ID = 'SET_TEMPLATE_ID'
export const SELECT_CUSTOMER = 'SELECT_CUSTOMER'
export const RESET_SELECTED_CUSTOMER = 'RESET_SELECTED_CUSTOMER'
export const SET_SELECT_ALL_STATE = 'SET_SELECT_ALL_STATE'
export const RESET_SELECTED_ESTIMATES = 'RESET_SELECTED_ESTIMATES'
export const UPDATE_ESTIMATE_STATUS = 'UPDATE_ESTIMATE_STATUS'
