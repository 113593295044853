var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "setting-main-container" }, [
    _c(
      "form",
      {
        attrs: { action: "" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.updateCompany.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "card setting-card" }, [
          _c("div", { staticClass: "page-header" }, [
            _c("h3", { staticClass: "page-title" }, [
              _vm._v(_vm._s(_vm.$t("settings.company_info.company_info"))),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "page-sub-title" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("settings.company_info.section_description")) +
                  "\n        "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-6 mb-4" },
              [
                _c("label", { staticClass: "input-label" }, [
                  _vm._v(_vm._s(_vm.$tc("settings.company_info.company_name"))),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "text-danger" }, [_vm._v(" * ")]),
                _vm._v(" "),
                _c("base-input", {
                  attrs: {
                    invalid: _vm.$v.formData.name.$error,
                    placeholder: _vm.$t("settings.company_info.company_name"),
                  },
                  on: {
                    input: function ($event) {
                      return _vm.$v.formData.name.$touch()
                    },
                  },
                  model: {
                    value: _vm.formData.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "name", $$v)
                    },
                    expression: "formData.name",
                  },
                }),
                _vm._v(" "),
                _vm.$v.formData.name.$error
                  ? _c("div", [
                      !_vm.$v.formData.name.required
                        ? _c("span", { staticClass: "text-danger" }, [
                            _vm._v(_vm._s(_vm.$tc("validation.required"))),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-6 mb-4" },
              [
                _c("label", { staticClass: "input-label" }, [
                  _vm._v(_vm._s(_vm.$tc("settings.company_info.phone"))),
                ]),
                _vm._v(" "),
                _c("base-input", {
                  attrs: {
                    name: "company-phone",
                    type: "number",
                    max: 12,
                    min: 8,
                    placeholder: _vm.$t("settings.company_info.phone"),
                  },
                  model: {
                    value: _vm.formData.phone,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "phone", $$v)
                    },
                    expression: "formData.phone",
                  },
                }),
                _vm._v(" "),
                _vm.$v.formData.phone.$error
                  ? _c("div", [
                      !_vm.$v.formData.phone.minLength
                        ? _c("span", { staticClass: "text-danger" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$tc(
                                  "validation.number_min",
                                  _vm.$v.formData.phone.$params.minLength.min,
                                  {
                                    min: _vm.$v.formData.phone.$params.minLength
                                      .min,
                                  }
                                )
                              )
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.$v.formData.phone.maxLength
                        ? _c("span", { staticClass: "text-danger" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$tc(
                                  "validation.number_max",
                                  _vm.$v.formData.phone.$params.maxLength.max,
                                  {
                                    max: _vm.$v.formData.phone.$params.maxLength
                                      .max,
                                  }
                                )
                              )
                            ),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-6 mb-4" },
              [
                _c("label", { staticClass: "input-label" }, [
                  _vm._v(_vm._s(_vm.$tc("settings.company_info.country"))),
                ]),
                _c("span", { staticClass: "text-danger" }, [_vm._v(" * ")]),
                _vm._v(" "),
                _c("base-select", {
                  class: { error: _vm.$v.formData.country_id.$error },
                  attrs: {
                    options: _vm.countries,
                    searchable: true,
                    "show-labels": false,
                    "allow-empty": false,
                    placeholder: _vm.$t("general.select_country"),
                    label: "name",
                    "track-by": "id",
                  },
                  model: {
                    value: _vm.country,
                    callback: function ($$v) {
                      _vm.country = $$v
                    },
                    expression: "country",
                  },
                }),
                _vm._v(" "),
                _vm.$v.formData.country_id.$error
                  ? _c("div", [
                      !_vm.$v.formData.country_id.required
                        ? _c("span", { staticClass: "text-danger" }, [
                            _vm._v(_vm._s(_vm.$tc("validation.required"))),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-6 mb-4" },
              [
                _c("label", { staticClass: "input-label" }, [
                  _vm._v(_vm._s(_vm.$tc("settings.company_info.state"))),
                ]),
                _vm._v(" "),
                _c("base-input", {
                  attrs: {
                    placeholder: _vm.$tc("settings.company_info.state"),
                    name: "state",
                    type: "text",
                  },
                  model: {
                    value: _vm.formData.state,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "state", $$v)
                    },
                    expression: "formData.state",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-6 mb-4" },
              [
                _c("label", { staticClass: "input-label" }, [
                  _vm._v(_vm._s(_vm.$tc("settings.company_info.city"))),
                ]),
                _vm._v(" "),
                _c("base-input", {
                  attrs: {
                    placeholder: _vm.$tc("settings.company_info.city"),
                    name: "city",
                    type: "text",
                  },
                  model: {
                    value: _vm.formData.city,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "city", $$v)
                    },
                    expression: "formData.city",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-6 mb-4" },
              [
                _c("label", { staticClass: "input-label" }, [
                  _vm._v(_vm._s(_vm.$tc("settings.company_info.zip"))),
                ]),
                _vm._v(" "),
                _c("base-input", {
                  attrs: {
                    type: "number",
                    max: 10,
                    placeholder: _vm.$tc("settings.company_info.zip"),
                  },
                  model: {
                    value: _vm.formData.zip,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "zip", $$v)
                    },
                    expression: "formData.zip",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-6 mb-4" },
              [
                _c("label", { staticClass: "input-label" }, [
                  _vm._v(_vm._s(_vm.$tc("settings.company_info.address_1"))),
                ]),
                _vm._v(" "),
                _c("base-text-area", {
                  class: { invalid: _vm.$v.formData.address_street_1.$error },
                  attrs: {
                    placeholder: _vm.$tc("general.street_1"),
                    rows: "2",
                  },
                  on: {
                    input: function ($event) {
                      return _vm.$v.formData.address_street_1.$touch()
                    },
                  },
                  model: {
                    value: _vm.formData.address_street_1,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "address_street_1", $$v)
                    },
                    expression: "formData.address_street_1",
                  },
                }),
                _vm._v(" "),
                _vm.$v.formData.address_street_1.$error
                  ? _c("div", [
                      !_vm.$v.formData.address_street_1.maxLength
                        ? _c("span", { staticClass: "text-danger" }, [
                            _vm._v(
                              _vm._s(_vm.$tc("validation.address_maxlength"))
                            ),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("label", { staticClass: "input-label mt-2" }, [
                  _vm._v(_vm._s(_vm.$tc("settings.company_info.address_2"))),
                ]),
                _vm._v(" "),
                _c("base-text-area", {
                  class: { invalid: _vm.$v.formData.address_street_2.$error },
                  attrs: {
                    placeholder: _vm.$tc("general.street_2"),
                    rows: "2",
                  },
                  on: {
                    input: function ($event) {
                      return _vm.$v.formData.address_street_2.$touch()
                    },
                  },
                  model: {
                    value: _vm.formData.address_street_2,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "address_street_2", $$v)
                    },
                    expression: "formData.address_street_2",
                  },
                }),
                _vm._v(" "),
                _vm.$v.formData.address_street_2.$error
                  ? _c("div", [
                      !_vm.$v.formData.address_street_2.maxLength
                        ? _c("span", { staticClass: "text-danger" }, [
                            _vm._v(
                              _vm._s(_vm.$tc("validation.address_maxlength"))
                            ),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-12" },
              [
                _c(
                  "base-button",
                  {
                    attrs: {
                      loading: _vm.isLoading,
                      disabled: _vm.isLoading,
                      icon: "save",
                      color: "theme",
                      type: "submit",
                    },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$tc("settings.company_info.save")) +
                        "\n          "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }