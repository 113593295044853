export const RESET_CURRENT_INVENTORY = 'RESET_CURRENT_INVENTORY'
export const BOOTSTRAP_CURRENT_INVENTORY = 'BOOTSTRAP_CURRENT_INVENTORY'
export const UPDATE_CURRENT_INVENTORY = 'UPDATE_CURRENT_INVENTORY'

export const BOOTSTRAP_INVENTORIES = 'BOOTSTRAP_INVENTORIES'
export const ADD_INVENTORY = 'ADD_INVENTORY'
export const UPDATE_INVENTORY = 'UPDATE_INVENTORY'
export const DELETE_INVENTORY = 'DELETE_INVENTORY'
export const DELETE_MULTIPLE_INVENTORY = 'DELETE_MULTIPLE_INVENTORY'
export const SET_SELECTED_INVENTORY = 'SET_SELECTED_INVENTORY'
export const SET_TOTAL_INVENTORIES = 'SET_TOTAL_INVENTORIES'
export const RESET_SELECTED_INVENTORY = 'RESET_SELECTED_INVENTORY'
export const SET_SELECT_ALL_STATE = 'SET_SELECT_ALL_STATE'
