var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-content item-create" }, [
    _c("div", { staticClass: "page-header" }, [
      _c("h3", { staticClass: "page-title" }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm.ledgerData ? _vm.ledgerData.account : "") +
            "\n    "
        ),
      ]),
      _vm._v(" "),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              {
                attrs: { slot: "item-title", to: "/invoices" },
                slot: "item-title",
              },
              [_vm._v(_vm._s(_vm.$t("general.home")))]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              {
                attrs: { slot: "item-title", to: "/ledgers" },
                slot: "item-title",
              },
              [_vm._v(_vm._s(_vm.$tc("ledgers.ledgers_list", 2)))]
            ),
          ],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12" }, [
        _c("div", { staticClass: "card" }, [
          _c(
            "div",
            { staticClass: "card-body" },
            [
              _c(
                "table-component",
                {
                  ref: "table",
                  attrs: {
                    data: _vm.displayArray,
                    "show-filter": false,
                    "table-class": "table display-ledger",
                  },
                },
                [
                  _c("table-column", {
                    attrs: { label: _vm.$t("ledgers.date"), show: "date" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (row) {
                          return [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.getFormattedDate(row.date)) +
                                "\n              "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("table-column", {
                    attrs: {
                      label: _vm.$t("ledgers.particulars"),
                      show: "particulars",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (row) {
                          return [
                            _c(
                              "router-link",
                              {
                                staticClass: "dropdown-item",
                                attrs: {
                                  to: {
                                    path: row.invoice_id
                                      ? `/invoices/${row.invoice_id}/edit`
                                      : row.receipt_id
                                      ? `/receipts/${row.receipt_id}/edit`
                                      : `/vouchers/${row.id}/edit`,
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(row.account) +
                                    "\n                "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("table-column", {
                    attrs: {
                      label: _vm.$t("ledgers.voucher_type"),
                      show: "voucher_type",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (row) {
                          return [
                            _vm._v(
                              "\n                " +
                                _vm._s(row.voucher_type) +
                                "\n              "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("table-column", {
                    attrs: { label: _vm.$t("ledgers.voucher_id"), show: "id" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (row) {
                          return [
                            _vm._v(
                              "\n                " +
                                _vm._s(row.id) +
                                "\n              "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("table-column", {
                    attrs: {
                      label: _vm.$t("daybook.inventory_item_quantity"),
                      show: "quantity",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (row) {
                          return [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  row.invoice
                                    ? row.invoice.inventories
                                        .map((k) => parseInt(k.quantity))
                                        .reduce((a, b) => a + b)
                                    : 0
                                ) +
                                "\n              "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("table-column", {
                    attrs: { label: _vm.$t("ledgers.debit"), show: "debit" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (row) {
                          return [
                            _vm._v(
                              "\n                ₹ " +
                                _vm._s(row.debit ? row.debit : "0.00") +
                                "\n              "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("table-column", {
                    attrs: { label: _vm.$t("ledgers.credit"), show: "credit" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (row) {
                          return [
                            _vm._v(
                              "\n                ₹ " +
                                _vm._s(row.credit ? row.credit : "0.00") +
                                "\n              "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row", staticStyle: { float: "right" } }, [
          _c(
            "div",
            { staticClass: "col-sm-12", staticStyle: { width: "500px" } },
            [
              _c("p", { staticClass: "row" }, [
                _c("span", { staticClass: "mr-30" }, [
                  _vm._v("Total Quantity:"),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "ml-60" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.totalQuantity) +
                      "\n            "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _vm.masterData
                ? _c("p", { staticClass: "row" }, [
                    _c("span", [_vm._v("Opening Balance:")]),
                    _vm._v(" "),
                    _c("span", { staticClass: "ml-60" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.masterData.type === "Dr" &&
                              _vm.masterData.opening_balance
                              ? " ₹ " +
                                  parseFloat(
                                    _vm.masterData.opening_balance
                                  ).toFixed(2) +
                                  " " +
                                  _vm.masterData.type
                              : " ₹ 0.00"
                          ) +
                          "\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "ml-60" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.masterData.type === "Cr" &&
                              _vm.masterData.opening_balance
                              ? " ₹ " +
                                  parseFloat(
                                    _vm.masterData.opening_balance
                                  ).toFixed(2) +
                                  " " +
                                  _vm.masterData.type
                              : " ₹ 0.00"
                          ) +
                          "\n            "
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c("hr"),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }