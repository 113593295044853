var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "items main-content" },
    [
      _c("div", { staticClass: "page-header" }, [
        _c("div", { staticClass: "d-flex flex-row" }, [
          _c("div", [
            _c("h3", { staticClass: "page-title" }, [
              _vm._v(_vm._s(_vm.$tc("daybook.title", 2))),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c(
                "router-link",
                { attrs: { slot: "item-title", to: "/" }, slot: "item-title" },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("general.home")) +
                      "\n        "
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c(
                "router-link",
                { attrs: { slot: "item-title", to: "#" }, slot: "item-title" },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$tc("daybook.title", 2)) +
                      "\n        "
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "page-actions row" }, [
          _c(
            "div",
            { staticClass: "col-xs-2 mr-2" },
            [
              _c(
                "base-button",
                {
                  attrs: {
                    outline: true,
                    color: "theme",
                    size: "large",
                    "right-icon": "",
                  },
                  on: { click: _vm.toggleLedgers },
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("general.to_display")) +
                      "\n        "
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-xs-2 mr-4" },
            [
              _c(
                "base-button",
                {
                  attrs: {
                    outline: true,
                    icon: _vm.filterIcon,
                    color: "theme",
                    size: "large",
                    "right-icon": "",
                  },
                  on: { click: _vm.toggleFilter },
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("general.filter")) +
                      "\n        "
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showFilters,
                expression: "showFilters",
              },
            ],
            staticClass: "filter-section",
          },
          [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-3" }, [
                _c("div", { staticClass: "filter-date" }, [
                  _c(
                    "div",
                    { staticClass: "from pr-3" },
                    [
                      _c("label", [_vm._v(_vm._s(_vm.$t("general.from")))]),
                      _vm._v(" "),
                      _c("base-date-picker", {
                        attrs: {
                          "calendar-button": true,
                          "calendar-button-icon": "calendar",
                        },
                        model: {
                          value: _vm.filters.from_date,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "from_date", $$v)
                          },
                          expression: "filters.from_date",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-3" }, [
                _c(
                  "div",
                  { staticClass: "to pl-3" },
                  [
                    _c("label", [_vm._v(_vm._s(_vm.$t("general.to")))]),
                    _vm._v(" "),
                    _c("base-date-picker", {
                      attrs: {
                        "calendar-button": true,
                        "calendar-button-icon": "calendar",
                      },
                      model: {
                        value: _vm.filters.to_date,
                        callback: function ($$v) {
                          _vm.$set(_vm.filters, "to_date", $$v)
                        },
                        expression: "filters.to_date",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-sm-2" },
                [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v(" " + _vm._s(_vm.$tc("daybook.account")) + " "),
                  ]),
                  _vm._v(" "),
                  _c("base-input", {
                    attrs: {
                      type: "text",
                      name: "account",
                      autocomplete: "off",
                    },
                    model: {
                      value: _vm.filters.account,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "account", $$v)
                      },
                      expression: "filters.account",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-sm-2" },
                [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$tc("daybook.voucher_debit")) + " "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("base-input", {
                    attrs: {
                      type: "text",
                      name: "voucher_debit",
                      autocomplete: "off",
                    },
                    model: {
                      value: _vm.filters.debit,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "debit", $$v)
                      },
                      expression: "filters.debit",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-sm-2" },
                [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$tc("daybook.voucher_credit")) + " "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("base-input", {
                    attrs: {
                      type: "text",
                      name: "voucher_credit",
                      autocomplete: "off",
                    },
                    model: {
                      value: _vm.filters.credit,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "credit", $$v)
                      },
                      expression: "filters.credit",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "label",
                { staticClass: "clear-filter", on: { click: _vm.clearFilter } },
                [_vm._v(" " + _vm._s(_vm.$t("general.clear_all")))]
              ),
            ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showEmptyScreen,
              expression: "showEmptyScreen",
            },
          ],
          staticClass: "col-xs-1 no-data-info",
          attrs: { align: "center" },
        },
        [
          _c("satellite-icon", { staticClass: "mt-5 mb-4" }),
          _vm._v(" "),
          _c("div", { staticClass: "row", attrs: { align: "center" } }, [
            _c("label", { staticClass: "col title" }, [
              _vm._v(_vm._s(_vm.$t("daybook.no_daybook"))),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "label",
              {
                staticClass: "description col mt-1",
                attrs: { align: "center" },
              },
              [_vm._v(_vm._s(_vm.$t("daybook.list_of_daybook")))]
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showEmptyScreen,
              expression: "!showEmptyScreen",
            },
          ],
          staticClass: "table-container",
        },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "table-actions mt-5 mb-5" },
              [
                _c(
                  "transition",
                  { attrs: { name: "fade" } },
                  [
                    _vm.selectedLedgers.length
                      ? _c("v-dropdown", { attrs: { "show-arrow": false } }, [
                          _c(
                            "span",
                            {
                              staticClass:
                                "table-actions-button dropdown-toggle",
                              attrs: { slot: "activator", href: "#" },
                              slot: "activator",
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("general.actions")) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticStyle: { float: "right" } },
                  [
                    _c(
                      "base-button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.fetchData,
                            expression: "fetchData",
                          },
                        ],
                        style: ["position: absolute", " margin-right: 5%"],
                        attrs: {
                          outline: true,
                          icon: ["fas", "print"],
                          color: "theme",
                          size: "large",
                          "right-icon": "",
                        },
                        on: { click: _vm.printfetchData },
                      },
                      [_vm._v("\n              Print\n            ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "table-component",
            {
              ref: "table",
              attrs: {
                id: "printData",
                data: _vm.fetchData,
                "show-filter": false,
                "table-class": "table",
              },
            },
            [
              _c("table-column", {
                attrs: { label: _vm.$t("daybook.date"), show: "date" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.getFormattedDate(row.date)) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("table-column", {
                attrs: { label: _vm.$t("daybook.account"), show: "account" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c(
                          "router-link",
                          {
                            staticClass: "dropdown-item",
                            attrs: {
                              to: {
                                path: row.invoice_id
                                  ? `/invoices/${row.invoice_id}/edit`
                                  : row.receipt_id
                                  ? `/receipts/${row.receipt_id}/edit`
                                  : `/vouchers/${row.id}/edit`,
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(row.account) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("table-column", {
                attrs: {
                  label: _vm.$t("daybook.voucher_type"),
                  show: "voucher_type",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _vm._v(
                          "\n           " +
                            _vm._s(row.voucher_type) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("table-column", {
                attrs: { label: _vm.$t("daybook.quantity"), show: "quantity" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _vm._v(
                          "\n           " +
                            _vm._s(parseInt(row.quantity)) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("table-column", {
                attrs: {
                  label: _vm.$t("daybook.voucher_debit"),
                  show: "voucher_debit",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _vm._v(
                          "\n          ₹ " +
                            _vm._s(row.voucher_debit) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("table-column", {
                attrs: {
                  label: _vm.$t("daybook.voucher_credit"),
                  show: "voucher_credit",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _vm._v(
                          "\n          ₹ " +
                            _vm._s(row.voucher_credit) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("table-column", {
                key: Math.random(),
                attrs: {
                  sortable: false,
                  filterable: false,
                  "cell-class": "action-dropdown",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(" " + _vm._s(_vm.$t("daybook.action")) + " "),
                        ]),
                        _vm._v(" "),
                        _c(
                          "v-dropdown",
                          [
                            _c(
                              "span",
                              {
                                attrs: { slot: "activator", href: "#" },
                                slot: "activator",
                              },
                              [_c("dot-icon")],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-dropdown-item",
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "dropdown-item",
                                    attrs: { to: { path: `${row.id}/edit` } },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      staticClass: "dropdown-item-icon",
                                      attrs: { icon: ["fas", "pencil-alt"] },
                                    }),
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("general.view")) +
                                        "\n            "
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }