var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("header", { staticClass: "site-header" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "a",
      {
        staticClass: "nav-toggle",
        attrs: { href: "#" },
        on: { click: _vm.onNavToggle },
      },
      [_vm._m(1)]
    ),
    _vm._v(" "),
    _c("ul", { staticClass: "action-list" }, [
      _c(
        "li",
        { staticClass: "notifications" },
        [
          _c(
            "v-dropdown",
            { attrs: { "show-arrow": false } },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-success",
                  attrs: {
                    slot: "activator",
                    href: "#",
                    "data-toggle": "dropdown",
                    "aria-haspopup": "true",
                    "aria-expanded": "false",
                  },
                  slot: "activator",
                },
                [
                  _c("font-awesome-icon", {
                    staticClass: "dropdown-item-icon",
                    attrs: { icon: "bell" },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "badge bg-secondary" }, [
                    _vm._v(
                      _vm._s(
                        _vm.listNotifications ? _vm.listNotifications.length : 0
                      )
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-dropdown-item",
                _vm._l(_vm.listNotifications, function (item, index) {
                  return _c("div", { key: index }, [
                    _c(
                      "div",
                      {
                        staticClass: "alert alert-success",
                        attrs: { role: "alert" },
                      },
                      [
                        _c("p", { staticClass: "mb-1" }, [
                          _c(
                            "a",
                            {
                              staticClass: "bg-transparent",
                              attrs: {
                                href: "/invoices/create?id=" + item.data.id,
                              },
                            },
                            [
                              _vm._v("Estimate Number : "),
                              _c(
                                "span",
                                { staticClass: "badge badge-success" },
                                [_vm._v(_vm._s(item.data.estimate_number))]
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ])
                }),
                0
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "li",
        [
          _c(
            "v-dropdown",
            { attrs: { "show-arrow": false } },
            [
              _c(
                "a",
                {
                  staticClass: "avatar",
                  attrs: {
                    slot: "activator",
                    href: "#",
                    "data-toggle": "dropdown",
                    "aria-haspopup": "true",
                    "aria-expanded": "false",
                  },
                  slot: "activator",
                },
                [
                  _c("img", {
                    attrs: { src: _vm.profilePicture, alt: "Avatar" },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "v-dropdown-item",
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "dropdown-item",
                      attrs: { to: "/settings" },
                    },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "dropdown-item-icon",
                        attrs: { icon: "cogs" },
                      }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("navigation.settings")) + " "
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-dropdown-item", [
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.logout.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "dropdown-item-icon",
                      attrs: { icon: "sign-out-alt" },
                    }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(" " + _vm._s(_vm.$t("navigation.logout")) + " "),
                    ]),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { staticClass: "brand-main", attrs: { href: "/" } }, [
      _c("img", {
        staticClass: "d-none d-md-inline",
        attrs: {
          id: "logo-white",
          src: "/assets/img/bill.png",
          alt: "Omtbiz Logo",
        },
      }),
      _vm._v(" "),
      _c("img", {
        staticClass: "d-md-none",
        attrs: {
          id: "logo-mobile",
          src: "/assets/img/bill.png",
          alt: "Laraspace Logo",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "hamburger hamburger--arrowturn" }, [
      _c("div", { staticClass: "hamburger-box" }, [
        _c("div", { staticClass: "hamburger-inner" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }