var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-content item-create" }, [
    _c("div", { staticClass: "page-header" }, [
      _c("h3", { staticClass: "page-title" }, [
        _vm._v(
          _vm._s(
            _vm.isEdit
              ? _vm.$t("inventory.edit_inventory")
              : _vm.$t("inventory.new_inventory")
          )
        ),
      ]),
      _vm._v(" "),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              {
                attrs: { slot: "item-title", to: "/invoices" },
                slot: "item-title",
              },
              [_vm._v(_vm._s(_vm.$t("general.home")))]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              {
                attrs: { slot: "item-title", to: "/inventory" },
                slot: "item-title",
              },
              [_vm._v(_vm._s(_vm.$tc("inventory.inventory", 2)))]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item" }, [
          _c("a", { attrs: { href: "#" } }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.isEdit
                    ? _vm.$t("inventory.edit_inventory")
                    : _vm.$t("inventory.new_inventory")
                )
            ),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col col-12 col-md-12 col-lg-6" }, [
        _c("div", { staticClass: "card" }, [
          _c(
            "form",
            {
              attrs: { action: "" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submitInventory.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "card-body" }, [
                _vm.isEdit
                  ? _c(
                      "span",
                      {
                        staticStyle: {
                          "font-style": "italic",
                          "font-size": "10px",
                        },
                      },
                      [
                        _vm._v(
                          "Edit will show latest inventory item here and you can't edit old items"
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v(_vm._s(_vm.$t("inventory.name"))),
                    ]),
                    _c("span", { staticClass: "text-danger" }, [_vm._v(" *")]),
                    _vm._v(" "),
                    _c("base-input", {
                      attrs: {
                        invalid: _vm.$v.formData.name.$error,
                        focus: "",
                        type: "text",
                        name: "name",
                        disabled: _vm.isEdit,
                      },
                      model: {
                        value: _vm.formData.name,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData,
                            "name",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formData.name",
                      },
                    }),
                    _vm._v(" "),
                    _vm.$v.formData.name.$error
                      ? _c("div", [
                          !_vm.$v.formData.name.required
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("validation.required")) + " "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.$v.formData.name.minLength
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$tc(
                                        "validation.name_min_length",
                                        _vm.$v.formData.name.$params.minLength
                                          .min,
                                        {
                                          count:
                                            _vm.$v.formData.name.$params
                                              .minLength.min,
                                        }
                                      )
                                    ) +
                                    "\n                "
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v(_vm._s(_vm.$t("inventory.worker_name"))),
                    ]),
                    _vm._v(" "),
                    _c("base-input", {
                      attrs: { focus: "", type: "text", name: "name" },
                      model: {
                        value: _vm.formData.worker_name,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData,
                            "worker_name",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formData.worker_name",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v(_vm._s(_vm.$t("inventory.quantity"))),
                    ]),
                    _c("span", { staticClass: "text-danger" }, [_vm._v(" *")]),
                    _vm._v(" "),
                    _c("base-input", {
                      attrs: {
                        focus: "",
                        invalid: _vm.$v.formData.quantity.$error,
                        type: "number",
                        min: "0",
                        name: "quantity",
                        disabled: _vm.isEdit,
                      },
                      model: {
                        value: _vm.formData.quantity,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData,
                            "quantity",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formData.quantity",
                      },
                    }),
                    _vm._v(" "),
                    _vm.$v.formData.quantity.$error
                      ? _c("div", [
                          !_vm.$v.formData.quantity.required
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("validation.required")) + " "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.$v.formData.quantity.minValue
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$tc(
                                        "validation.quantity_min_length",
                                        _vm.$v.formData.name.$params.minValue
                                          .min,
                                        {
                                          count:
                                            _vm.$v.formData.name.$params
                                              .minLength.min,
                                        }
                                      )
                                    ) +
                                    "\n                "
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v(_vm._s(_vm.$t("inventory.price"))),
                    ]),
                    _c("span", { staticClass: "text-danger" }, [_vm._v(" *")]),
                    _vm._v(" "),
                    _c("base-input", {
                      attrs: {
                        focus: "",
                        invalid: _vm.$v.formData.price.$error,
                        type: "number",
                        min: "0",
                        name: "price",
                      },
                      model: {
                        value: _vm.formData.price,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData,
                            "price",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formData.price",
                      },
                    }),
                    _vm._v(" "),
                    _vm.$v.formData.price.$error
                      ? _c("div", [
                          !_vm.$v.formData.price.required
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("validation.required")) + " "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.$v.formData.price.minValue
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$tc(
                                        "validation.price_min_length",
                                        _vm.$v.formData.name.$params.minValue
                                          .min,
                                        {
                                          count:
                                            _vm.$v.formData.name.$params
                                              .minLength.min,
                                        }
                                      )
                                    ) +
                                    "\n                "
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v(_vm._s(_vm.$t("inventory.sale_price"))),
                    ]),
                    _vm._v(" "),
                    _c("base-input", {
                      attrs: {
                        focus: "",
                        type: "number",
                        min: "0",
                        name: "sale_price",
                      },
                      model: {
                        value: _vm.formData.sale_price,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData,
                            "sale_price",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formData.sale_price",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v(_vm._s(_vm.$t("inventory.unit"))),
                    ]),
                    _vm._v(" "),
                    _c("base-select", {
                      staticClass: "hide-select-header",
                      attrs: {
                        options: _vm.unitOptions,
                        "allow-empty": false,
                        "show-labels": false,
                        searchable: false,
                      },
                      model: {
                        value: _vm.formData.unit,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "unit", $$v)
                        },
                        expression: "formData.unit",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(
                      "base-button",
                      {
                        staticClass: "collapse-button",
                        attrs: {
                          loading: _vm.isLoading,
                          disabled: _vm.isLoading,
                          icon: "save",
                          color: "theme",
                          type: "submit",
                        },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.isEdit
                                ? _vm.$t("inventory.update_inventory")
                                : _vm.$t("inventory.save_inventory")
                            ) +
                            "\n              "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _vm.relatedInventories.length
      ? _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col col-12 col-md-12 col-lg-12" }, [
            _c("div", { staticClass: "card" }, [
              _c("h5", { staticClass: "p-3" }, [_vm._v("Related Inventory")]),
              _vm._v(" "),
              _c(
                "table",
                { staticClass: "p-3 m-3" },
                [
                  _vm._m(0),
                  _vm._v(" "),
                  _vm._l(_vm.relatedInventories, function (each, index) {
                    return _c("tr", { key: index }, [
                      _c("td", [_vm._v(_vm._s(each.id))]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(_vm.formData.name))]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(each.worker_name))]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(each.quantity))]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(each.price))]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(each.sale_price))]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(each.unit))]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(each.date_time))]),
                    ])
                  }),
                ],
                2
              ),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", [_vm._v("ID")]),
      _vm._v(" "),
      _c("th", [_vm._v("Name")]),
      _vm._v(" "),
      _c("th", [_vm._v("Worker Name")]),
      _vm._v(" "),
      _c("th", [_vm._v("Quantity")]),
      _vm._v(" "),
      _c("th", [_vm._v("Cost Price")]),
      _vm._v(" "),
      _c("th", [_vm._v("Sale Price")]),
      _vm._v(" "),
      _c("th", [_vm._v("Unit")]),
      _vm._v(" "),
      _c("th", [_vm._v("Date/Time")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }